import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BaseService } from './base.service';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ParkingService {
  body: any;

  constructor(
    private http: Http,
    private autheticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  temporarilyFreeParking(from?, to?, now = "false") {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES",
      "MediaPlaza": "false",
      "LiberarAhora": now,
      "FechaLibre_Desde": from,
      "FechaLibre_Hasta": to
    }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Plaza_LiberarTemporalmente${
          token
        }`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  cancelTemporarilyReleasedParking(id) {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES",
      "idLibre": id
   }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Cancelar_LiberadaTemporalmente${
          token
        }`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  listTemporarilyRelease() {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = { "Localizacion_Idioma": 'es-ES' };
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Listado_LiberarTemporalmente${
          token
        }`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  cancelReservation() {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Plaza_Cancelar_Reserva${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  requestInterval(from, to) {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES",
      "FechaSolicitud_Desde": from,
      "FechaSolicitud_Hasta": to
    }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/solicitarPlazaIntervalo${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  listAvailableParking() {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = { "Localizacion_Idioma": 'es-ES' };
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Listado_Solicitudes${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  cancelParking(id) {
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES",
      "idSolicitud": id
   }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/Cancelar_Solicitud${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  addToParkingRaffle(){
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES"
   }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/alta${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  removeFromParkingRaffle(){
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES"
   }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/baja${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  requestPlace(){
    let token: string = `?access_token=${this.autheticationService.getToken()}`;
    this.body = {
      "Localizacion_Idioma": "es-ES"
   }
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/parking/solicitarPlaza${token}`,
        this.body,
        OPTIONS
      )
      .map(res => {
        return res.json();
      })
      .catch(error => this.baseService.handleError(this.autheticationService, error));
  }

  getParkingOccupation(id: number, token: string) {
    const url = `${SERVICE_BASE_URL}/api/parking/occupation/${id}?access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.autheticationService, error)
      );
  }

  getParkingOccupationHistoric(id: number, token: string) {
    const url = `${SERVICE_BASE_URL}/api/parking/occupation/${id}/historic?access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.autheticationService, error)
      );
  }
}
