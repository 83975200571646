import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WaitingListWs } from '../../core/models/waiting.list.ws';

@Component({
  selector: 'waitingDetail',
  templateUrl: 'waiting.detail.component.html',
  styleUrls: ['waiting-list-card.scss']
})
export class WaitingDetailComponent {
  @Input() waiting: WaitingListWs;
  @Output() waitingEmitter = new EventEmitter<WaitingListWs>();
  @Input() viewCard: boolean;

  cancelWaiting() {
    this.waitingEmitter.emit(this.waiting);
  }
}
