import { Injectable } from '@angular/core';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormService {

  constructor() { }

  openModal: (type:string) => void;

  closModal: () => void;

  setUserProfile: (user: UserLdapWs) => void;

}
