import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/services/login.service';
import { STATUS_CONFLICT } from '../../core/constants/const';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import globals = require('../../globals');
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loginComponent',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup;
  errorLogin: string;
  usernameActive: boolean;
  passwordActive: boolean;
  loginBtnShow: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.errorLogin = '';
    this.usernameActive = false;
    this.passwordActive = false;
    this.loginBtnShow = true;

    if (this.authenticationService.existLogedUser()) {
      this.router.navigate(['/menu/home']);
    }
  }

  login(userData: any) {
    this.loginBtnShow = false;
    this.loginService.login(userData.username, userData.password).subscribe(
      response => {
        this.authenticationService.saveToken(response);
        this.errorLogin = '';

        if (globals.redirectPage.length > 0) {
          this.router.navigate([globals.redirectPage]);
          globals.redirectPage = '';
        } else {
          if (response.headquarters && response.headquarters.id) {
            this.router.navigate(['/menu/home']);
          } else {
            this.router.navigate(['/menu/profile']);
          }
        }
      },
      error => {
        this.formLogin.controls['password'].setValue('');
        this.loginBtnShow = true;

        if (error.code == STATUS_CONFLICT) {
          this.errorLogin = error.message;
        } else {
          let messagePipe = new MessagesPipe();
          this.errorLogin = messagePipe.transform('error_login');
        }
      }
    );
  }

  setUsernameActive() {
    this.usernameActive = true;
  }

  removeUsernameActive() {
    if (this.formLogin.controls['username'].value.length == 0) {
      this.usernameActive = false;
    }
  }

  setPasswordActive() {
    this.passwordActive = true;
  }

  removePasswordActive() {
    if (this.formLogin.controls['password'].value.length == 0) {
      this.passwordActive = false;
    }
  }

  get canResetPassword(): boolean {
    return environment['resetPassword'];
  }
}
