import {
  trigger,
  style,
  state,
  transition,
  animate
} from '@angular/animations';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_ACTIVE_OPACITY,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY
} from '../../core/constants/consts';

export function slideButtons() {
  return trigger('slideButtons', [
    state(
      ANIMATE_CLASS_INACTIVE,
      style({
        display: 'none',
        opacity: 0
      })
    ),
    state(
      ANIMATE_CLASS_ACTIVE,
      style({
        display: 'block',
        opacity: 1
      })
    ),
    transition(
      `${ANIMATE_CLASS_INACTIVE} => ${ANIMATE_CLASS_ACTIVE}`,
      animate('300ms')
    ),
    transition(
      `${ANIMATE_CLASS_ACTIVE} => ${ANIMATE_CLASS_INACTIVE}`,
      animate('300ms')
    )
  ]);
}

export function slideDetailsBooking() {
  return trigger('slideDetailsBooking', [
    state(
      ANIMATE_CLASS_INACTIVE,
      style({
        transform: 'translateX(110%)',
        opacity: 0
      })
    ),
    state(
      ANIMATE_CLASS_ACTIVE,
      style({
        transform: 'translateX(0%)',
        opacity: 1
      })
    ),
    state(
      ANIMATE_CLASS_ACTIVE_OPACITY,
      style({
        display: 'block',
        opacity: 1
      })
    ),
    state(
      ANIMATE_CLASS_INACTIVE_OPACITY,
      style({
        display: 'none',
        opacity: 0
      })
    ),
    transition(
      `${ANIMATE_CLASS_INACTIVE} => ${ANIMATE_CLASS_ACTIVE}`,
      animate('400ms')
    ),
    transition(
      `${ANIMATE_CLASS_ACTIVE} => ${ANIMATE_CLASS_INACTIVE}`,
      animate('300ms')
    ),
    transition(
      `${ANIMATE_CLASS_INACTIVE_OPACITY} => ${ANIMATE_CLASS_ACTIVE_OPACITY}`,
      animate('100ms')
    ),
    transition(
      `${ANIMATE_CLASS_ACTIVE_OPACITY} => ${ANIMATE_CLASS_INACTIVE_OPACITY}`,
      animate('300ms')
    )
  ]);
}
