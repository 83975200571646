import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ParkingService } from '../../shared/services/parking.service';
import { STATUS_ERROR_EXTERNAL } from '../../core/constants/const';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-parking-occupation',
  templateUrl: './parking-occupation.component.html',
  styleUrls: ['./parking-occupation.component.scss'],
})
export class ParkingOccupationComponent implements OnInit {
  @ViewChild('defaultOpen', null) defaultTab: ElementRef;
  nNormalBuildingA: number;
  nNormalBuildingB: number;
  nElectricBuildingA: number;
  nElectricBuildingB: number;
  messagesPipe = new MessagesPipe();
  token: string;

  // Data
  public lineChartData: ChartDataSets[] = [];

  // Axis X
  public lineChartLabels: Label[] = [];

  // Chart Options
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
        },
      ],
    },
    title: {
      text: '',
      fontSize: 20,
      fontColor: 'rgba(0,0,0,1)',
      display: true,
    },
    legend: {
      position: 'bottom',
    },
  };

  // Colors of the lines
  public lineChartColors: Color[] = [
    {
      borderColor: '#f58418',
    },
    {
      borderColor: '#5283c8',
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';

  constructor(
    private parkingService: ParkingService,
    private flashMessagesService: FlashMessagesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.token = params['token'];
    });

    this.defaultTab.nativeElement.click();
    this.getParkingOccupationBuildingA(1);
    this.getParkingOccupationBuildingB(2);
    this.getParkingOccupationHistoric(1);
  }

  getParkingOccupationBuildingA(id: any) {
    this.parkingService
      .getParkingOccupation(id, this.token)
      .subscribe((res) => {
        if (res) {
          this.nNormalBuildingA = res.normal;
          this.nElectricBuildingA = res.electric;
        }
      });
  }

  getParkingOccupationBuildingB(id: any) {
    this.parkingService.getParkingOccupation(id, this.token).subscribe(
      (res) => {
        if (res) {
          this.nNormalBuildingB = res.normal;
          this.nElectricBuildingB = res.electric;
        }
      },
      (error) => {
        if (error.code === STATUS_ERROR_EXTERNAL) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('parking_occupation_external_error'),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            }
          );
        }
      }
    );
  }

  getParkingOccupationHistoric(id: any) {
    this.parkingService.getParkingOccupationHistoric(id, this.token).subscribe(
      (res) => {
        if (res) {
          this.lineChartLabels = res.labels ? res.labels : '';
          this.lineChartData = [
            {
              data: res.normalValues ? res.normalValues : '',
              label: this.messagesPipe.transform(
                'parking_occupation_normal_parking'
              ),
            },
            {
              data: res.electricValues ? res.electricValues : '',
              label: this.messagesPipe.transform(
                'parking_occupation_electric_parking'
              ),
            },
          ];
        }
      },
      (error) => {
        if (error.code === STATUS_ERROR_EXTERNAL) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('parking_occupation_external_error'),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            }
          );
        }
      }
    );
  }

  manageTabs(evt: any, building: number) {
    let i: number, tablinks: any;

    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    evt.currentTarget.className += ' active';

    this.getParkingOccupationHistoric(building);
  }
}
