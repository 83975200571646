import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  STATUS_CONFLICT,
  STATUS_FORBIDDEN
} from "../../../core/constants/const";
import { LOGOUT_TIMEOUT } from "../../../core/constants/consts";
import { ReservationWs } from "../../../core/models/reservation.ws";
import { RoomListResponseWs } from "../../../core/models/room.list.response.ws";
import { RoomListWs } from "../../../core/models/room.list.ws";
import { RoomWs } from "../../../core/models/room.ws";
import { DateStringPipe } from "../../../shared/pipes/date.string.pipe";
import { MessagesPipe } from "../../../shared/pipes/messages.pipe";
import { AuthenticationService } from "../../../shared/services/authentication.service";
import { BaseService } from "../../../shared/services/base.service";
import { FlashMessagesService } from "../../../shared/services/flash-messages.service";
import { ReservationService } from "../../../shared/services/reservation.service";
import { RoomService } from "../../../shared/services/room.service";
import { Utils } from "../../../shared/utils/utils";

@Component({
  selector: "roomWithoutTimeMosaic",
  templateUrl: "room.without.time.mosaic.html",
  styleUrls: ["room-available.scss"]
})
export class RoomWithoutTimeMosaic implements OnInit {
  availableRooms: RoomListResponseWs[];
  roomListResponseSelect: RoomListResponseWs;
  reservationBtnDisabled: boolean;
  reservationDate: Date;
  reservationDateDetail: Date;
  roomDetail: RoomWs;
  modalReservation: ReservationWs;
  showModal: boolean;
  showServerLunchMsg: boolean;
  reservationResponse: string;

  constructor(
    private roomService: RoomService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private utils: Utils,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private reservationService: ReservationService
  ) {
    this.reservationBtnDisabled = false;
    this.availableRooms = [];
    this.roomListResponseSelect = new RoomListResponseWs();
    this.roomListResponseSelect.rooms = [];
    this.roomDetail = new RoomWs("", "", "", "", "");
    this.roomDetail.reservations = [];
    this.reservationDate = new Date();
    this.reservationDateDetail = new Date();
    this.modalReservation = new ReservationWs();
    this.showModal = false;
    this.showServerLunchMsg = false;
    this.reservationResponse = "";
  }

  ngOnInit() {
    if (
      !this.authenticationService.getPermissionsForCurrentUser()
        .allowReservation
    ) {
      this.router.navigate(["/menu/home"]);
    }

    if (localStorage.getItem("reservationFilterNoTime")) {
      let reservation = JSON.parse(
        localStorage.getItem("reservationFilterNoTime")
      ) as ReservationWs;
      let actualDate = new Date();

      if (
        reservation.dateFromInMillis &&
        this.utils.compareDatesWithoutTime(
          reservation.dateFromInMillis,
          actualDate
        ) >= 0
      ) {
        this.reservationDate = new Date(reservation.dateFromInMillis);
        this.getRooms(this.reservationDate);
      } else {
        localStorage.removeItem("reservationFilterNoTime");
        this.router.navigate(["/menu/reservations"]);
      }
    } else {
      this.router.navigate(["/menu/reservations"]);
    }
  }

  getRooms(dateFrom: Date) {
    let messagesPipe = new MessagesPipe();

    this.roomService
      .getRoomsByDate(dateFrom, this.authenticationService.getToken())
      .subscribe(
        roomList => {
          this.availableRooms = roomList;

          if (this.availableRooms.length <= 0) {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              messagesPipe.transform("avaiableRooms_empty"),
              { cssClass: "alert-danger", timeout: 3000 }
            );
            this.router.navigate(["/menu/reservations"]);
          } else {
            this.roomListResponseSelect = this.availableRooms[0];
          }
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.getRooms(dateFrom);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.getRooms(dateFrom);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              messagesPipe.transform("available_rooms_list_error")
            );
            this.router.navigate(["/menu/home"]);
          }
        }
      );
  }

  showModalReservationCard($event) {
    this.modalReservation = new ReservationWs();
    this.modalReservation.room = $event.room;
    this.modalReservation.dateFrom = this.reservationDate;
    this.showModal = true;
  }

  showRoomDetail($event) {
    if (!this.reservationBtnDisabled) {
      this.roomDetail = $event;
      this.reservationDateDetail = new Date(this.reservationDate.getTime());
    }
  }

  closeRoomDetail() {
    this.roomDetail = new RoomWs("", "", "", "", "");
    this.roomDetail.reservations = [];
  }

  closeDialog() {
    this.modalReservation = new ReservationWs();
    this.showModal = false;
  }

  changeBuilding(roomListResponse: RoomListResponseWs): void {
    this.closeRoomDetail();
    this.roomListResponseSelect = roomListResponse;
  }

  showModalReservation($event) {
    this.modalReservation = $event;
    this.showModal = true;
  }

  saveReservation($event) {
    this.reservationBtnDisabled = true;
    this.closeRoomDetail();
    this.reserveRoom($event);
  }

  reserveRoom(reservation: ReservationWs) {
    let roomList = new RoomListWs(
      reservation.dateFrom,
      reservation.dateTo,
      reservation.reason,
      parseInt(reservation.room.capacityMax)
    );
    let messagesPipe = new MessagesPipe();
    let successMessage = "";
    this.reservationService
      .reservationBooking(
        reservation.room,
        roomList,
        this.authenticationService.getToken()
      )
      .subscribe(
        response => {
          let dateStringPipe = new DateStringPipe();
          localStorage.removeItem("reservationFilterNoTime");

          if (response.message != null && response.message.length > 0) {
            this.showServerLunchMsg = true;
            this.reservationResponse = response.message;
            this.closeDialog();
          } else {
            successMessage = messagesPipe.transform("room_reserved_msg", [
              reservation.room.name,
              dateStringPipe.transform(roomList.dateFrom.toString(), "DD/MM"),
              dateStringPipe.transform(roomList.dateFrom.toString(), "HH:mm"),
              dateStringPipe.transform(roomList.dateTo.toString(), "HH:mm")
            ]);

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(successMessage, {
              cssClass: "alert-success",
              timeout: 3000
            });

            this.router.navigate(["/menu/reservations"]);
          }
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.reserveRoom(reservation);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.reserveRoom(reservation);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              messagesPipe.transform("reserve_creation_error")
            );

            if (error.code == STATUS_CONFLICT) {
              localStorage.removeItem("reservationFilterNoTime");
              this.router.navigate(["/menu/reservations"]);
            } else {
              this.router.navigate(["/menu/home"]);
            }
          }
        }
      );
  }

  backToReservationList(): void {
    this.router.navigate(["/menu/reservations"]);
  }
}
