import { Component, OnInit } from '@angular/core';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { IncidenceService } from '../../shared/services/incidence.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { Incidence } from '../../core/models/incidence';

@Component({
  selector: 'app-incidence',
  templateUrl: './incidence.component.html',
  styleUrls: ['./incidence.component.scss']
})
export class IncidenceComponent implements OnInit {

  listIncidence: Incidence[];
  showForm: boolean = false;

  constructor( 
    private incidenceService: IncidenceService,
    private navigatorRefreshService: NavigatorRefreshService
    ) { }

  ngOnInit() {
    let messagesPipe = new MessagesPipe();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      messagesPipe.transform('menu_incidence')
    );
    this.getIncidence();
  }

  getIncidence(){
    this.incidenceService.getIncidence().subscribe( res => {
      if(res){
        this.listIncidence = res;
      }
    })
  }

  openModalForm(){
    this.showForm = true;
  }

  closeFormNewIncidence(){
    this.getIncidence();
    this.showForm = false;
  }

}
