import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild
} from '@angular/core';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_ACTIVE_OPACITY,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY,
  AVAILABLE_DURATION,
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES,
  AVAILABLE_MINUTES_SPEED,
  RESERVATION_STATE_CHECKED
} from '../../../core/constants/consts';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom.date.pipe';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { ReservationEdit } from '../../../core/models/reservation.edit';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationSpeedDetail',
  templateUrl: 'reservation.speed.detail.component.html',
  styleUrls: ['reservation-speed-detail.scss'],
  animations: [slideDetailsBooking()]
})
export class ReservationSpeedDetailComponent implements OnInit, OnChanges {
  @Input() reservationWs: ReservationWs;
  @Output() eventCancel = new EventEmitter<ReservationWs>();
  @Output() eventCloseDetail = new EventEmitter<any>();
  @Output() eventEdit = new EventEmitter<ReservationEdit>();
  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectDuration', null) selectDuration: any;
  showEditModal: boolean;
  hours: string[];
  dateStringPipe: DateStringPipe;
  dateFrom: Date;
  dateTo: Date;
  hoursFrom: string[];
  minutesFrom = AVAILABLE_MINUTES_SPEED;
  hourFrom: string;
  reason: string;
  reservationDuration: number;
  errorDatesInterval: boolean;
  disableSaveEditBtn: boolean;
  duration = AVAILABLE_DURATION;
  editReservationDateError: string;

  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService
  ) {
    let minHourIndex;
    let minDate;
    this.showEditModal = false;
    this.dateStringPipe = new DateStringPipe();
    this.reason = '';

    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        this.dateStringPipe.transform(minDate, 'HH')
      );

      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    this.hours = this.utils.getSpeedReservationsHours();
  }

  ngOnChanges() {
    if (this.reservationWs.id) {
      this.reservationWs.room.reservations = this.reservationWs.room.reservations.filter(
        reservation => {
          return reservation.id != this.reservationWs.id;
        }
      );
      this.dateFrom = new Date(this.reservationWs.dateFrom.getTime());
      this.dateTo = new Date(this.reservationWs.dateTo.getTime());
      this.reservationDuration = this.reservationWs.reservationDuration + 0;
    } else {
      this.dateFrom = new Date();
      this.dateTo = new Date();
      this.reservationDuration = 0;
    }

    this.showEditModal = false;
    this.editReservationDateError = '';
  }

  getDetailReservationAnimateOpacity(): string {
    if (this.reservationWs.id) {
      return ANIMATE_CLASS_ACTIVE_OPACITY;
    } else {
      return ANIMATE_CLASS_INACTIVE_OPACITY;
    }
  }

  getDetailReservationAnimate(): string {
    if (this.reservationWs.id) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  getTime(): string {
    let messagesPipe;

    if (this.reservationWs.id) {
      messagesPipe = new MessagesPipe();

      return messagesPipe.transform('time_str', [
        this.dateStringPipe.transform(
          this.reservationWs.dateFrom.toString(),
          'HH:mm'
        ),
        this.dateStringPipe.transform(
          this.reservationWs.dateTo.toString(),
          'HH:mm'
        )
      ]);
    } else {
      return '';
    }
  }

  editReservation(): void {
    let dateAux;
    let durationIndex;
    if (this.dateFrom.getHours().toString().length > 1) {
      this.hourFrom = this.dateFrom.getHours().toString();
    } else {
      this.hourFrom = `0${this.dateFrom.getHours()}`;
    }

    this.selectHourFrom.nativeElement.selectedIndex = this.hoursFrom.indexOf(
      this.hourFrom
    );

    if (this.dateFrom.getMinutes().toString().length > 1) {
      this.hourFrom = `${this.hourFrom}:${this.dateFrom.getMinutes()}`;
      this.selectMinuteFrom.nativeElement.selectedIndex = this.minutesFrom.indexOf(
        this.dateFrom.getMinutes().toString()
      );
    } else {
      this.hourFrom = `${this.hourFrom}:0${this.dateFrom.getMinutes()}`;
      this.selectMinuteFrom.nativeElement.selectedIndex = this.minutesFrom.indexOf(
        `0${this.dateFrom.getMinutes()}`
      );
    }

    dateAux = new Date(
      this.reservationWs.dateTo.getTime() -
        this.reservationWs.dateFrom.getTime()
    );
    durationIndex = this.duration.indexOf(`${dateAux.getMinutes()}`);

    if (durationIndex >= 0) {
      this.selectDuration.nativeElement.selectedIndex = durationIndex;
      this.reservationDuration = dateAux.getMinutes();
    } else {
      this.selectDuration.nativeElement.selectedIndex = 0;
      this.reservationDuration = 0;
    }

    this.reason = this.reservationWs.reason;
    this.showEditModal = true;
    this.errorDatesInterval = false;
    this.disableSaveEditBtn = false;
  }

  cancelReservation(): void {
    this.eventCancel.emit(this.reservationWs);
  }

  cancelEdit(): void {
    this.showEditModal = false;
  }

  getEditModalRoomName(): string {
    let dateStringPipe = new DateStringPipe();
    let customDatePipe = new CustomDatePipe();

    if (this.reservationWs && this.reservationWs.room) {
      return `${this.reservationWs.room.name} - ${dateStringPipe.transform(
        this.dateFrom.toString(),
        'DD'
      )} ${customDatePipe.transform(this.dateFrom.getTime())}`;
    } else {
      return '';
    }
  }

  onChangeHourFrom($event) {
    let minute = this.hourFrom.split(':')[1];

    this.hourFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    let hour = this.hourFrom.split(':')[0];

    this.hourFrom = `${hour}:${$event.target.value}`;
  }

  onChangeDuration($event) {
    this.reservationDuration = parseInt($event.target.value);
  }

  saveEditReservation(): void {
    let reservationEdit;
    let messagePipe = new MessagesPipe();
    let timePipe = new CustomTimePipe();
    let editDateFrom = timePipe.transform(this.dateFrom, this.hourFrom);
    let actualDate = new Date();
    this.disableSaveEditBtn = true;

    if (this.utils.compareDates(editDateFrom, actualDate) < 0) {
      this.errorDatesInterval = true;
      this.disableSaveEditBtn = false;
      this.editReservationDateError = messagePipe.transform(
        'date_from_greater_actual'
      );
    } else {
      let dateTo = new Date(editDateFrom.getTime());
      dateTo.setMinutes(dateTo.getMinutes() + this.reservationDuration);

      if (dateTo.getDay() != editDateFrom.getDay()) {
        this.errorDatesInterval = true;
        this.disableSaveEditBtn = false;
        this.editReservationDateError = messagePipe.transform(
          'date_from_in_day'
        );
      } else {
        reservationEdit = new ReservationEdit(
          this.reservationWs,
          editDateFrom,
          null,
          this.reason,
          this.reservationDuration
        );
        this.eventEdit.emit(reservationEdit);
      }
    }
  }

  getClassLaunchTime(): string {
    let lunchDateIni = new Date(this.reservationWs.room.lunchStartingTime);
    let lunchDateEnd = new Date(this.reservationWs.room.lunchEndingTime);

    return `lunch start-${this.utils.getSpeedPosHour(
      this.hours,
      lunchDateIni
    )} end-${this.utils.getSpeedPosHour(this.hours, lunchDateEnd)}`;
  }

  getClassScheduleDiv(dateIni: any, dateEnd: any) {
    return this.utils.getSpeedClassScheduleDiv(this.hours, dateIni, dateEnd);
  }

  getIsChecked(): boolean {
    if (
      this.reservationWs.status != null &&
      this.reservationWs.status.id != null
    ) {
      if (RESERVATION_STATE_CHECKED == this.reservationWs.status.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  closeDetail(): void {
    this.eventCloseDetail.emit();
  }
}
