import { MsalAngularConfiguration } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { environment } from 'src/environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment['clientId'],
      authority: environment['authority'],
      validateAuthority: true,
      redirectUri: environment['redirectUrl'],
      postLogoutRedirectUri: environment['redirectUrl'],
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    }
  };
}

function MSALConfigAngularFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: ['user.read', 'openid', 'profile'],
    unprotectedResources: [],
    protectedResourceMap: [
      ['Enter_the_Graph_Endpoint_Herev1.0/me', ['user.read']],
    ],
    extraQueryParameters: {},
  };
}

export { MSALConfigFactory, MSALConfigAngularFactory };
