import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { SERVICE_BASE_URL } from "../../../environments/environment";
import { OPTIONS } from "../../core/constants/const";
import { UserLdapWs } from "../../core/models/user.ldap.ws";
import { AuthenticationService } from "./authentication.service";
import { BaseService } from "./base.service";

@Injectable()
export class UserService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getUserDetail(token: string): Observable<UserLdapWs> {
    let bodyString = JSON.stringify({});

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/detailWithTime?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as UserLdapWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  updateProfile(token: string, user: UserLdapWs): Observable<UserLdapWs> {
    let bodyString = JSON.stringify(user);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/update?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as UserLdapWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  updatePassword(token: string, password: string, newPassword: string): Observable<any> {
    let bodyString = JSON.stringify({
      password: password,
      newPassword: newPassword
    });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/changePassword?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  listUsersLdapByFilter(
    token: string,
    filter?: string
  ): Observable<UserLdapWs[]> {
    let bodyString = JSON.stringify({
      filter,
    });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/filter-search-ldap?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as UserLdapWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  searchByUserAndDepartment(
    token: string,
    search: string
    ): Observable<any[]> {
    let bodyString = JSON.stringify({
      search
    });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/search-user-department?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  searchUserByDepartment(
    token: string,
    search: string,
    departmentId: number
  ): Observable<any[]> {
    let bodyString = JSON.stringify({
      departmentId: departmentId,
      userName: search,
    });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/search-user-department?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getUserPermissions(token: string): Observable<UserLdapWs> {

    const BODY_STRING = JSON.stringify({});

    return this.http
      .post(`${SERVICE_BASE_URL}/api/user/detail?access_token=${token}`, BODY_STRING, OPTIONS)
      .map((response: Response) => {
        return response.json() as UserLdapWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getDinnerShifts(token: string, day: string): Observable<any[]> {
    const bodyString = JSON.stringify({
      day
    });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/user/dinnerShifts?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
