import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { SERVICE_BASE_URL } from "../../../environments/environment";
import { OPTIONS } from "../../core/constants/const";
import { Observable } from 'rxjs';
import { Vehicle } from '../../core/models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
    ) { }

    addVehicle(token: string, vehicle: Vehicle): Observable<any> {
      let bodyString = JSON.stringify(vehicle);
  
      return this.http
        .post(
          `${SERVICE_BASE_URL}/api/vehicle/?access_token=${token}`,
          bodyString,
          OPTIONS
        )
        .map((response: Response) => {
          return response.ok;
        })
        .catch((error) =>
          this.baseService.handleError(this.authenticationService, error)
        );
    }

    editVehicle(token: string, vehicle: Vehicle): Observable<any> {
      let bodyString = JSON.stringify(vehicle);
  
      return this.http
        .post(
          `${SERVICE_BASE_URL}/api/vehicle/${vehicle.id}/?access_token=${token}`,
          bodyString,
          OPTIONS
        )
        .map((response: Response) => {
          return response.ok;
        })
        .catch((error) =>
          this.baseService.handleError(this.authenticationService, error)
        );
    }

    removeVehicle(token: string, id: number): Observable<any> {
      let bodyString = JSON.stringify({});
  
      return this.http
        .delete(
          `${SERVICE_BASE_URL}/api/vehicle/${id}/?access_token=${token}`,
          bodyString
        )
        .map((response: Response) => {
          return response.ok;
        })
        .catch((error) =>
          this.baseService.handleError(this.authenticationService, error)
        );
    }

    getTypesVehicle(token: string): Observable<any> {
      let bodyString = JSON.stringify({});
  
      return this.http
        .get(
          `${SERVICE_BASE_URL}/api/vehicle/types/?access_token=${token}`,
          bodyString
        )
        .map((response: Response) => {
          return response.json();
        })
        .catch((error) =>
          this.baseService.handleError(this.authenticationService, error)
        );
    }
}
