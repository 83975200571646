import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { OPTIONS } from '../../core/constants/const';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { Http } from '@angular/http';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IncidenceService {

  constructor( 
    private http : Http, 
    private baseService: BaseService, 
    private authenticationService: AuthenticationService,
    ) { }

  getIncidence(): Observable<any>{
    const url  =  `${SERVICE_BASE_URL}/api/incidences/list?access_token=${this.authenticationService.getToken()}`;
    let bodyString = JSON.stringify({});
    return this.http.post(
      url,
      bodyString,
      OPTIONS
    )
    .map((response: any) => {
      return response.json();
    })
    .catch(error =>{ 
      return this.baseService.handleError(this.authenticationService, error)
    }
    );
  }

  altaIncidence(incidence){
    const url = `${SERVICE_BASE_URL}/api/incidences/alta?access_token=${this.authenticationService.getToken()}`;
    let bodyString = JSON.stringify({
      idEdificio :incidence.idBuilding,
    	idPlanta: incidence.idFloor,
    	idZona : incidence.idZona,
    	idRecurso : incidence.idResource,
    	idCategoria : incidence.idCategory,
    	descripcion : incidence.description,
    	nombre : incidence.name,
    	imagen : incidence.image
    });
    return this.http.post(
      url,
      bodyString,
      OPTIONS
    )
    .map((response: any) => {
      return response;
    })
    .catch(error =>{ 
      return this.baseService.handleError(this.authenticationService, error)
    }
    );
  }
}
