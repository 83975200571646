import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { BaseService } from '../../../shared/services/base.service';
import { AttendanceService } from '../../../shared/services/attendance.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-edit-assistance',
  templateUrl: './modal-edit-assistance.component.html',
  styleUrls: ['./modal-edit-assistance.component.scss'],
  animations: [slideDetailsBooking()],
})
export class ModalEditAssistanceComponent implements OnInit, OnChanges {
  @Input() showForm: boolean;
  @Input() assistanceConfirm: boolean;
  @Input() assistancesList: any;
  @Input() assistanceSelected: any;
  @Input() mode: any;
  @Input() listUsersForDay: UserLdapWs;
  @Input() assistanceInfo: any;
  @Output() closeFormEmmitter = new EventEmitter<any>();

  assistanceForm: FormGroup;
  turnOK = false;
  listUsers: any;
  messagesPipe = new MessagesPipe();

  permissions: MenuPermissions;
  user: UserLdapWs;
  dinnerShift: any;
  shiftPriority: any;

  buttonDisabledConfirm = false;
  buttonDisabledRequestParking = false;
  buttonDisabledRequestDinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
    this.assistanceForm = this.formBuilder.group({
      turn: [this.shiftPriority ? this.shiftPriority : '0'],
    });
    this.dinnerShift = this.user.dinnerShiftFrom
      ? moment(this.user.dinnerShiftFrom).format('HH:mm')
      : '';
    this.shiftPriority =
      this.user.dinnerShiftFrom && this.user.dinnerShiftTo
        ? moment(this.user.dinnerShiftFrom).format('HHmm') +
          '' +
          moment(this.user.dinnerShiftTo).format('HHmm')
        : null;
    if (
      this.assistanceForm &&
      parseInt(this.assistanceForm.controls['turn'].value, 10) > 0
    ) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  checkShiftPriority(value: any) {
    const turn = this.assistanceForm.controls['turn'].value;
    let res = true;
    value.forEach((element) => {
      if (element.id === turn) {
        res = false;
      }
    });

    if (res) {
      this.assistanceForm.controls['turn'].setValue('0');
      this.turnOK = false;
    }

    return res;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  closeForm() {
    this.showForm = false;
    this.shiftPriority = null;
    this.assistanceForm.reset();
    this.closeFormEmmitter.emit();
    this.listUsersForDay = null;
  }

  getAnimations(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  changeTurn(value: any) {
    if (parseInt(value, 10) > 0) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  cancelAssistance() {
    this.attendanceService
      .cancelAssistance(this.assistanceSelected.assignments.id)
      .subscribe(
        (res) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_cancel_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_ko')
          );
          this.closeForm();
        }
      );
  }

  confirmAssistance() {
    this.buttonDisabledConfirm = true;
    const attendancesSave = [];
    const valueTurn =
      this.assistanceForm.controls['turn'].value >= 0
        ? parseInt(this.assistanceForm.controls['turn'].value, 10)
        : null;
    this.assistancesList.forEach((element) => {
      let availableTurn = null;
      if (valueTurn) {
        availableTurn = element.info.availableTurns.filter((turn) => {
          return parseInt(turn.id, 10) === valueTurn;
        });
      }

      if (availableTurn && element.indexWeek !== 5) {
        const availableTurnsAux = {
          shiftFrom:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftFrom
              : '',
          shiftTo:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftTo
              : '',
        };
        element.info.availableTurns = [availableTurnsAux];
      } else if (!availableTurn && element.indexWeek !== 5) {
        element.info.availableTurns = [];
      } else {
        element.info.availableTurns = [];
        element.info.availableDesk = {};
      }
      attendancesSave.push(element.info);
    });
    this.update(attendancesSave);
  }

  activeIconParking() {
    return this.assistanceInfo &&
      this.assistanceInfo.parking.hasParking &&
      this.assistanceInfo.parking.id
      ? ` active`
      : ``;
  }

  activeIconDinner() {
    return this.assistanceInfo &&
      this.assistanceInfo.dinner.hasReservation &&
      this.assistanceInfo.dinner.id
      ? ` active`
      : ``;
  }

  activeIconParkingNoConf(assistance: any) {
    return assistance.info &&
      assistance.info.parking &&
      assistance.info.parking.hasRequestParking &&
      assistance.info.parking.code
      ? ` active`
      : ``;
  }

  activeIconDinnerNoConf(assistance: any) {
    return assistance.indexWeek !== 5 &&
      assistance.info &&
      assistance.info.hasRequestDinnerDesk &&
      assistance.info.availableDesk &&
      assistance.info.availableDesk.id
      ? ` active`
      : ``;
  }

  solictTurn() {
    this.buttonDisabledRequestDinner = true;
    const optionSelected = this.assistanceForm.controls['turn'].value;
    const assistanceSelect = this.assistanceInfo.dinner.availableTurns.filter(
      (element) => {
        return parseInt(element.id, 10) === parseInt(optionSelected, 10);
      }
    );
    const request = {
      day: this.assistanceInfo.attendance.day,
      availableTurns: {
        shiftFrom:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftFrom
            : '',
        shiftTo:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftTo
            : '',
      },
    };
    this.attendanceService.requestDinnerDesk(request).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_dinner_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_dinner_ko')
        );
        this.closeForm();
      }, () => {
        this.buttonDisabledRequestDinner = false;
        }
    );
  }

  update(attendances: any) {
    this.attendanceService.update(attendances).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_confirm_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_update_error')
        );
        this.closeForm();
      }, () => {
        this.buttonDisabledConfirm = false;
        }
    );
  }

  getActiveConfirm() {
    let find = false;
    this.assistancesList.forEach((element) => {
      if (
        element.info.availableTurns &&
        element.info.availableTurns.length > 0
      ) {
        find = true;
      }
    });
    if (this.assistanceForm.controls['turn'].value < 0 && find) {
      return ` a-not-active`;
    } else {
      return ` `;
    }
  }

  liberateOrSolictParking(value: any) {
    if (value) {
      this.liberateParkig();
    } else {
      this.solictParking();
    }
  }

  liberateParkig() {
    this.attendanceService
      .liberateParking(this.assistanceSelected.assignments.parking.assignmentId)
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'assistance_text_liberate_parking_ok'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_liberate_parking_ko')
          );
          this.closeForm();
        }
      );
  }

  solictParking() {
    this.buttonDisabledRequestParking = true;
    const solict = {
      day: moment(this.assistanceInfo.attendance.day).format('DD/MM/YYYY'),
      parking: {
        assignmentId: null,
        hasParking: false,
      },
    };

    this.attendanceService.solictParking(solict).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_parking_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
          this.router.navigate(['menu', 'assistance']);
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_parking_ko')
        );
        this.closeForm();
      }, () => {
        this.buttonDisabledRequestParking = false;
        }
    );
  }

  cancelTurn() {
    this.attendanceService
      .cancelDinnerDesk(this.assistanceInfo.dinner.id)
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform('assistance_text_cancel_dinner_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_dinner_ok')
          );
          this.closeForm();
        }
      );
  }
}
