import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { RoomDetailWs } from '../../core/models/room.detail.ws';
import { BuildingWs } from '../../core/models/building.ws';
import { ReservationWs } from '../../core/models/reservation.ws';
import { RoomListWs } from '../../core/models/room.list.ws';
import { RoomWs } from '../../core/models/room.ws';
import { RoomListResponseWs } from '../../core/models/room.list.response.ws';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';

@Injectable()
export class RoomService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getAvailableRooms(
    roomList: RoomListWs,
    token: string
  ): Observable<RoomListResponseWs[]> {
    let bodyString = JSON.stringify(roomList);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomListResponseWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getSpeedAvailableRooms(
    roomList: RoomListWs,
    token: string
  ): Observable<RoomListResponseWs[]> {
    let bodyString = JSON.stringify(roomList);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/speed/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomListResponseWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  filterRoomById(roomList: RoomWs[], id: number): RoomWs {
    let roomsSelected = roomList.filter(room => {
      return room.id == id;
    });

    if (roomsSelected && roomsSelected.length > 0) {
      return roomsSelected[0];
    } else {
      return null;
    }
  }

  getRoomDetail(id: number, date: Date, token: string): Observable<RoomWs> {
    let roomDetailWS = new RoomDetailWs(id, date);
    let bodyString = JSON.stringify(roomDetailWS);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/detail?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomWs;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getRoomDetailWithHeadquarters(
    reservationWs: ReservationWs,
    token: string
  ): Observable<RoomWs> {
    let roomDetailWS = new RoomDetailWs(
      reservationWs.room.id,
      reservationWs.dateFrom
    );
    let bodyString = JSON.stringify(roomDetailWS);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/detail?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomWs;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getRoomsByBuilding(
    building: BuildingWs,
    token: string
  ): Observable<RoomWs[]> {
    let bodyString = JSON.stringify(building);
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/listByBuilding?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getRoomsByDate(
    dateFrom: Date,
    token: string
  ): Observable<RoomListResponseWs[]> {
    let body = {
      dateFrom: dateFrom
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/room/states?access_token=${token}`,
        JSON.stringify(body),
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as RoomListResponseWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
