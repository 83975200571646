import { Component, OnInit, ViewChild } from '@angular/core';
import { Utils } from '../../shared/utils/utils';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { DalkonService } from '../../shared/services/dalkon.service';
import { MyDatePicker } from '../../shared/components/date_picker/datepicker.component';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { ParkingService } from '../../shared/services/parking.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import {
  PARKING_TYPE_FIJA,
  PARKING_TYPE_ROTACION,
  PARKING_TYPE_SIN_ASIGNAR,
  PARKING_TYPE_TEMPORAL,
} from 'src/app/core/constants/consts';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { NavigatorRefreshService } from 'src/app/shared/services/navigator.refresh.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parking-one',
  templateUrl: './parking-one.component.html',
  styleUrls: ['./parking-one.component.scss'],
})
export class ParkingOneComponent implements OnInit {
  language: string;
  parkingData: any;
  sidebarStatus: boolean;
  parkingDate: Date;
  datepickerOptions: any;
  editableDatePicker: boolean;
  permissions: MenuPermissions;
  checked1: boolean;
  list: any;
  date1Status: boolean;
  checkedRaffleStatus: boolean = false;
  checkedRaffle: boolean = false;
  parkingType: string;
  hideCederHoy: boolean = false;
  liberarAhora = false;
  cedidaHasta: string;
  parkingPlazaFechaHasta: String;
  parkingReservaPlazaFechaHasta: String;
  fechaSorteo: string;
  @ViewChild('from1', null) from1: MyDatePicker;
  @ViewChild('to1', null) to1: MyDatePicker;
  parkingSpotMessage: string;
  yieldParkingSpotDateMessage: string;
  requestParkingSpotMessage: string;
  yieldParkingSpotMessage: string;
  yieldOrRequestMessage: string;
  yieldParkingSpotTemporaryMessage: string;
  yieldParkingFloor: string;
  readonly PARKING_TYPE_FIJA: string = PARKING_TYPE_FIJA;
  readonly PARKING_TYPE_ROTACION: string = PARKING_TYPE_ROTACION;
  readonly PARKING_TYPE_SIN_ASIGNAR: string = PARKING_TYPE_SIN_ASIGNAR;
  readonly PARKING_TYPE_TEMPORAL: string = PARKING_TYPE_TEMPORAL;
  messagesPipe = new MessagesPipe();
  administrationEmail: string;

  constructor(
    private parkingService: ParkingService,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private dalkonService: DalkonService,
    private flashMessagesService: FlashMessagesService,
    private navigatorRefreshService: NavigatorRefreshService
  ) {}

  ngOnInit() {
    const messagesPipe = new MessagesPipe();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      messagesPipe.transform('menu_parking')
    );
    this.checkParking();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
    this.language = navigator.language;
    this.administrationEmail = environment['administrationEmail'] ? environment['administrationEmail'] : 'cau@sdos.es';
  }

  isParkingTipoFijaOrTemp(): boolean {
    if (this.parkingData) {
      return (
        this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA ||
        this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_TEMPORAL
      );
    }
    return false;
  }

  isParkingTipoTemp(): boolean {
    if (this.parkingData) {
      return this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_TEMPORAL;
    }
    return false;
  }

  isParkingTipoRotacion(): boolean {
    if (this.parkingData) {
      return this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_ROTACION;
    }
    return false;
  }

  isParkingTipoSinAsignar(): boolean {
    if (this.parkingData) {
      return this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_SIN_ASIGNAR;
    }
    return false;
  }

  checkParking() {
    this.dalkonService
      .obtainData(this.authenticationService.getToken())
      .subscribe((res) => {
        this.parkingData = res;
        if (this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA) {
          this.parkingType = PARKING_TYPE_FIJA;
          this.listTemporarilyRelease();
            this.hideCederHoy = this.parkingData.Plaza_Cedida == 'true';
        } else if (
          this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_SIN_ASIGNAR
        ) {
          if (JSON.parse(this.parkingData.Alta_sorteo_rotacion) == true) {
            this.checkedRaffle = true;
          } else {
            this.checkedRaffle = false;
          }
          this.parkingType = 'Sin_Asignar';
          if (
            this.parkingData.Plaza_Cedida == 'true' &&
            this.parkingData.Parking_plazaReservada_Codigo
          ) {
            this.hideCederHoy = true;
          } else if (
            this.parkingData.Plaza_Cedida == 'false' &&
            this.parkingData.Parking_plazaReservada_Codigo
          ) {
            this.hideCederHoy = false;
          } else {
            this.hideCederHoy = true;
          }
        } else if (
          this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_TEMPORAL
        ) {
          this.parkingType = 'Temporal';
        } else if (
          this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_ROTACION
        ) {
          this.parkingType = 'Rotacion';
          if (JSON.parse(this.parkingData.Alta_sorteo_rotacion) == true) {
            this.checkedRaffle = true;
          } else {
            this.checkedRaffle = false;
          }
          if (this.parkingData.Plaza_Cedida == 'true') {
            this.hideCederHoy = true;
          } else {
            this.hideCederHoy = false;
          }
        }
        this.getTextDates();
        this.getMessages();
      });
  }

  listTemporarilyRelease() {
    this.parkingService.listTemporarilyRelease().subscribe((dates) => {
      this.clearFormData();
      this.setDatepickerOptions();
      this.list = dates.Valor_Devuelto;
      let index: number = 0;
      if (dates.Valor_Devuelto.length > 0) {

        this.liberarAhora = false;

        for (let date of dates.Valor_Devuelto) {
          index++;
          let formatFrom = new Date(Date.parse(date.FechaDesde));
          let formatTo = new Date(Date.parse(date.FechaHasta));

          if (!date.LiberarAhora) {
            this.utils.setDatePickerDate(formatFrom, this.from1);
            this.utils.setDatePickerDate(formatTo, this.to1);
            this.editableDatePicker = false;
            this.checked1 = true;
          } else {
            this.liberarAhora = date.LiberarAhora;
          }

          let today = new Date();
          if (formatFrom < today) {
            this.cedidaHasta = formatTo.toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            });
          }
        }
      } else {
        this.editableDatePicker = null;
      }
      this.checkDate1();
      this.getMessages();
    });
  }

  temporarilyFreeParking(from, to) {
    this.parkingService.temporarilyFreeParking(from, to).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'Tu plaza ha sido cedida correctamente'
          ),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            };
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: 'alert-danger', timeout: 3000 };
        }
        this.checkParking();
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          'Solicitud incorrecta. Revise las fechas.'
        ),
          {
            cssClass: 'alert-danger',
            timeout: 3000,
          };
        this.checkParking();
      }
    );
  }

  requestInterval(from, to) {
    this.parkingService.requestInterval(from, to).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show('Has solicitado plaza correctamente'),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            };
          this.checkParking();
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: 'alert-danger', timeout: 3000 };
          this.checkParking();
        }
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          'Solicitud incorrecta. Revise las fechas.'
        ),
          {
            cssClass: 'alert-danger',
            timeout: 3000,
          };
        this.checkParking();
      }
    );
  }

  freeParking() {
    let now = 'true';
    this.parkingService.temporarilyFreeParking('', '', now).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'Tu plaza ha sido cedida correctamente'
          ),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            };
          this.hideCederHoy = true;
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: 'alert-danger', timeout: 3000 };
        }
        this.checkParking();
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show('Solicitud incorrecta.'),
          { cssClass: 'alert-danger', timeout: 3000 };
        this.checkParking();
      }
    );
  }

  setDatepickerOptions() {
    if (this.parkingData.Fecha_sorteo) {
      const dateTimeFullAux = this.parkingData.Fecha_sorteo.split(' ');
      const dateAux = dateTimeFullAux[0].split('-');
      const timeAux = dateTimeFullAux[1].split(':');
      const currentDate = new Date();
      const formatSorteo = new Date(
        dateAux[0],
        dateAux[1] - 1,
        dateAux[2],
        timeAux[0],
        timeAux[1],
        timeAux[2],
        0
      );

      if (
        this.parkingType === PARKING_TYPE_FIJA &&
        currentDate < formatSorteo
      ) {
        this.datepickerOptions = {
          ...this.datepickerOptions,
          ...this.utils.setDatepickerOptions()
        };
      }
    } else {
      this.datepickerOptions = {
        ...this.datepickerOptions,
        ...this.utils.setDatepickerOptionsRequest()
      };
    }
  }

  onDateChanged($event, idDatePicker) {
    this.checkDate1();
    if ($event.formatted.length > 0 && this.datepickerOptions) {
      this.parkingDate = new Date();
      this.parkingDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
      if (idDatePicker == 1) {
        this.datepickerOptions.disableUntil = {
          year: this.parkingDate.getFullYear(),
          month: this.parkingDate.getMonth() + 1,
          day: this.parkingDate.getDate(),
        };
      } else if (idDatePicker == 2) {
        this.datepickerOptions.disableSince = {
          year: this.parkingDate.getFullYear(),
          month: this.parkingDate.getMonth() + 1,
          day: this.parkingDate.getDate(),
        };
      }
    } else if ($event.formatted.length <= 0 && this.datepickerOptions) {
      if (idDatePicker === 2) {
        this.datepickerOptions.disableSince = {
          year: 0,
          month: 0,
          day: 0,
        };

        this.datepickerOptions = { ...this.datepickerOptions };
      }
      this.setDatepickerOptions();
      this.parkingDate = null;
    }
    this.from1.setOptions();
    this.to1.setOptions();
  }

  toggleSidebar() {
    this.sidebarStatus = !this.sidebarStatus;
  }

  actionRaffle() {
    if (this.checkedRaffle) {
      this.parkingService.removeFromParkingRaffle().subscribe(
        (res) => {
          if (res && res.Valor_Devuelto == 'true') {
            this.checkedRaffle = false;
          } else {
            let check = document.getElementById(
              'switchRaffle'
            ) as HTMLInputElement;
            check.checked = true;
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              'Error no se ha podido quitar del sorteo'
            ),
              { cssClass: 'alert-danger', timeout: 3000 };
          }
          this.checkParking();
        },
        (error) => {
          let check = document.getElementById(
            'switchRaffle'
          ) as HTMLInputElement;
          check.checked = true;
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'Error no se ha podido quitar del sorteo'
          ),
            { cssClass: 'alert-danger', timeout: 3000 };
          this.checkParking();
        }
      );
    } else if (
      this.parkingData.Vehiculos &&
      this.parkingData.Vehiculos.length > 0
    ) {
      this.parkingService.addToParkingRaffle().subscribe(
        (res) => {
          if (res && res.Valor_Devuelto == 'true') {
            this.checkedRaffle = true;
          } else {
            let check = document.getElementById(
              'switchRaffle'
            ) as HTMLInputElement;
            check.checked = false;
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('parkingOne_raffle_end'),
              { cssClass: 'alert-error', timeout: 3000 }
            );
          }
          this.checkParking();
        },
        (error) => {
          let check = document.getElementById(
            'switchRaffle'
          ) as HTMLInputElement;
          check.checked = false;
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('parkingOne_raffle_end'),
            { cssClass: 'alert-error', timeout: 3000 }
          );
          this.checkParking();
        }
      );
    } else if (
      !this.parkingData.Vehiculos ||
      this.parkingData.Vehiculos.length <= 0
    ) {
      let check = document.getElementById('switchRaffle') as HTMLInputElement;
      check.checked = false;
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show('No tienes vehiculo asignado'),
        { cssClass: 'alert-danger', timeout: 3000 };
    }
  }

  requestPlace() {
    this.parkingService.requestPlace().subscribe(
      (res) => {
        if (res && res.Valor_Devuelto == 'true') {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show('Se te ha asignado una plaza'),
            { cssClass: 'alert-danger', timeout: 3000 };
        } else if (res.Errores) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: 'alert-danger', timeout: 3000 };
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show('No hay plaza disponible'),
            { cssClass: 'alert-danger', timeout: 3000 };
        }
        this.checkParking();
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show('Error al buscar una plaza'),
          { cssClass: 'alert-danger', timeout: 3000 };
        this.checkParking();
      }
    );
  }

  clearFormData() {
    this.from1.clearDate();
    this.to1.clearDate();
    this.checked1 = false;
    this.editableDatePicker = true;
  }

  checkDateStatus() {
    this.checkDate1();
  }

  checkDate1() {
    if (this.from1.selectedDate.day == 0 || this.to1.selectedDate.day == 0) {
      this.date1Status = false;
    } else {
      this.date1Status = true;
    }
  }

  actionDate(from, to) {
    if (this.checked1) {
      let id: number;
      if (this.parkingType == PARKING_TYPE_FIJA) {
        id = this.list.find((x) => !x.LiberarAhora).idLibre;
      }
      this.removeDate(id);
    } else {
      this.sendDate(from, to);
    }
  }

  sendDate(from, to) {
    let formatFrom = `${from.selectedDate.year}-${from.selectedDate.month}-${from.selectedDate.day}`;
    let formatTo = `${to.selectedDate.year}-${to.selectedDate.month}-${to.selectedDate.day}`;
    if (from.selectedDate.day == 0 || to.selectedDate.day == 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show('Debes seleccionar una fecha correcta'),
        {
          cssClass: 'alert-danger',
          timeout: 3000,
        };
    } else {
      if (this.parkingType == PARKING_TYPE_FIJA) {
        this.temporarilyFreeParking(formatFrom, formatTo);
      } else {
        this.requestInterval(formatFrom, formatTo);
      }
      this.checked1 = true;
    }
  }

  removeDate(id) {
    if (this.parkingType == PARKING_TYPE_FIJA) {
      this.parkingService.cancelTemporarilyReleasedParking(id).subscribe(
        (res) => {
          if (res.Errores == null) {
            if (!res.Valor_Devuelto) {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('parking_yielded_parkingConfirm'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              this.clearFormData();
            } else {
              const currentDate = new Date();
              const cancellationAssigmentDate = new Date(
                Date.parse(res.Valor_Devuelto)
              );

              if (
                currentDate.getDate() === cancellationAssigmentDate.getDate() &&
                currentDate.getMonth() ===
                cancellationAssigmentDate.getMonth() &&
                currentDate.getFullYear() === cancellationAssigmentDate.getFullYear()
              ) {
                this.flashMessagesService.grayOut(false);
                this.flashMessagesService.show(
                  this.messagesPipe.transform('parking_yielded_parkingConfirm'),
                  { cssClass: 'alert-danger', timeout: 3000 }
                );

                this.clearFormData();
              } else {

                const recoverDate = new Date(Date.parse(res.Valor_Devuelto)).toLocaleDateString(
                  'es-ES',
                  {
                    day: 'numeric',
                    month: 'short',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                );

                this.flashMessagesService.grayOut(false);
                this.flashMessagesService.show(
                  this.messagesPipe.transform('parking_yielded_parkingRecover') +
                    recoverDate,
                  { cssClass: 'alert-danger', timeout: 3000 }
                );
                let check = document.getElementById(
                  'switch1'
                ) as HTMLInputElement;
                check.checked = true;
              }
            }
          } else {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(res.Errores),
              { cssClass: 'alert-danger', timeout: 3000 };
          }
          this.checkParking();
        },
        (error) => {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'Error no se ha cancelado la cesión de tu plaza'
          ),
            { cssClass: 'alert-danger', timeout: 3000 };
          let check = document.getElementById('switch1') as HTMLInputElement;
          check.checked = true;
        }
      );
    }
  }

  getTextDates() {
    this.parkingPlazaFechaHasta = '';
    if (this.parkingData.Parking_plaza_FechaHasta) {
      let dateTimeFullAux = this.parkingData.Parking_plaza_FechaHasta.split(
        ' '
      );
      let dateAux = dateTimeFullAux[0].split('-');
      let timeAux = dateTimeFullAux[1].split(':');
      let formatSorteo = new Date(
        dateAux[0],
        dateAux[1] - 1,
        dateAux[2],
        timeAux[0],
        timeAux[1],
        timeAux[2],
        0
      );
      let dateNow = new Date();
      let dateMorning = new Date();
      this.parkingPlazaFechaHasta = formatSorteo.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
    this.parkingReservaPlazaFechaHasta = '';
    if (this.parkingData.Parking_plazaReservada_FechaHasta) {
      let dateTimeFullAux = this.parkingData.Parking_plazaReservada_FechaHasta.split(
        ' '
      );
      let dateAux = dateTimeFullAux[0].split('-');
      let timeAux = dateTimeFullAux[1].split(':');
      let formatSorteo = new Date(
        dateAux[0],
        dateAux[1] - 1,
        dateAux[2],
        timeAux[0],
        timeAux[1],
        timeAux[2],
        0
      );
      let dateNow = new Date();
      let dateMorning = new Date();
      this.parkingReservaPlazaFechaHasta = formatSorteo.toLocaleDateString(
        'es-ES',
        {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
        }
      );
    }

    this.fechaSorteo = '';
    if (this.parkingData.Fecha_sorteo) {
      let dateTimeFullAux = this.parkingData.Fecha_sorteo.split(' ');
      let dateAux = dateTimeFullAux[0].split('-');
      let timeAux = dateTimeFullAux[1].split(':');

      let formatSorteo = new Date(
        dateAux[0],
        dateAux[1] - 1,
        dateAux[2],
        timeAux[0],
        timeAux[1],
        timeAux[2],
        0
      );
      this.fechaSorteo = formatSorteo.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }

  private getMessages() {
    const messagesPipe = new MessagesPipe();

    if (this.parkingData != null && this.isParkingTipoSinAsignar) {
      this.parkingSpotMessage =
        messagesPipe.transform('parking_with_assigned_parkingSpot_today') +
        this.parkingData.Parking_plaza_Codigo;
    } else {
      this.parkingSpotMessage = messagesPipe.transform(
        'parking_without_assigned_parkingSpot'
      );
    }

    if (this.isParkingTipoFijaOrTemp()) {
      if (
        this.parkingData != null &&
        this.parkingData.Plaza_Cedida == 'true' &&
        this.cedidaHasta
      ) {
        this.yieldParkingSpotDateMessage =
          messagesPipe.transform('parking_yielded_parkingSpot') +
          this.cedidaHasta;
      } else {
        if (this.parkingType == PARKING_TYPE_FIJA) {
          this.yieldParkingSpotDateMessage = messagesPipe.transform(
            'parking_assigned_fixed_parkingSpot'
          );
        } else if (this.parkingType == PARKING_TYPE_TEMPORAL) {
          this.yieldParkingSpotDateMessage =
            messagesPipe.transform('parking_assigned_temporary_parkingSpot') +
            ' ' +
            this.parkingPlazaFechaHasta;
        }
      }
    } else if (
      this.isParkingTipoRotacion() &&
      this.parkingPlazaFechaHasta &&
      this.parkingData.Plaza_Cedida == 'false'
    ) {
      this.yieldParkingSpotDateMessage =
        messagesPipe.transform('parking_assigned_parkingSpot') +
        this.parkingPlazaFechaHasta;
    } else if (
      this.isParkingTipoRotacion() &&
      this.parkingPlazaFechaHasta &&
      this.parkingData.Plaza_Cedida == 'true'
    ) {
      this.yieldParkingSpotDateMessage = messagesPipe.transform(
        'parking_ceded_square'
      );
    } else if (
      this.isParkingTipoSinAsignar() &&
      this.parkingReservaPlazaFechaHasta &&
      this.parkingData.Plaza_Cedida == 'false'
    ) {
      this.yieldParkingSpotDateMessage =
        messagesPipe.transform('parking_assigned_parkingSpot') +
        this.parkingReservaPlazaFechaHasta;
    }

    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.requestParkingSpotMessage = messagesPipe.transform(
        'parking_request_seeDetails_btn'
      );
    }

    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldParkingSpotMessage = this.parkingData.Parking_plaza_Codigo;
      this.yieldParkingFloor = this.parkingData.Parking_plaza_planta;
    }

    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldOrRequestMessage = messagesPipe.transform(
        'parking_parkingSpot_absence'
      );
    }

    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldParkingSpotTemporaryMessage = messagesPipe.transform(
        'parking_yield_temporary'
      );
    }
  }
}
