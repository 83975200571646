import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { CustomDatePipe } from '../../../shared/pipes/custom.date.pipe';
import { RoomWs } from '../../../core/models/room.ws';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import {
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES
} from '../../../core/constants/consts';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationDialog',
  templateUrl: 'reservation.dialog.html',
  styleUrls: ['reservation-dialog.scss']
})
export class ReservationDialog implements OnChanges {
  @Input() reservation: ReservationWs;
  @Output() cancelEmitter = new EventEmitter<any>();
  @Output() saveEmitter = new EventEmitter<ReservationWs>();
  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectHourTo', null) selectHourTo: any;
  @ViewChild('selectMinuteTo', null) selectMinuteTo: any;
  room: RoomWs;
  reservationDate: Date;
  hoursFrom: string[];
  hoursTo: string[];
  minutesFrom = AVAILABLE_MINUTES;
  minutesTo = AVAILABLE_MINUTES;
  dateStringPipe: DateStringPipe;
  customDatePipe: CustomDatePipe;
  reason: string;
  errorDatesInterval: string;
  hourFrom: string;
  hourTo: string;
  disableButtons: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private utils: Utils
  ) {
    this.dateStringPipe = new DateStringPipe();
    this.customDatePipe = new CustomDatePipe();

    let minDate;
    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      let minHourIndex = AVAILABLE_HOURS.indexOf(
        this.dateStringPipe.transform(minDate, 'HH')
      );

      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
      this.hoursTo = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
      this.hoursTo = AVAILABLE_HOURS;
    }

    this.room = new RoomWs('', '', '', '', '');
    this.reservationDate = new Date();
    this.reason = '';
    this.errorDatesInterval = '';
    this.disableButtons = false;
  }

  ngOnChanges() {
    if (this.reservation != null && this.reservation.room.id != null) {
      this.room = this.reservation.room;
      this.reservationDate = this.reservation.dateFrom;
      this.errorDatesInterval = '';
      this.reason = '';

      this.hourFrom = this.utils.selectHoursSetPositionFrom(
        this.authenticationService,
        this.hoursFrom,
        this.minutesFrom,
        this.selectHourFrom,
        this.selectMinuteFrom
      );
      this.hourTo = this.utils.selectHoursSetPositionTo(
        this.hourFrom,
        this.selectHourTo,
        this.selectMinuteTo,
        this.selectHourFrom
      );
      this.disableButtons = false;
    }
  }

  getEditModalRoomName(): string {
    let dateStringPipe = new DateStringPipe();
    let customDatePipe = new CustomDatePipe();

    return `${this.room.name} - ${dateStringPipe.transform(
      this.reservationDate.toString(),
      'DD'
    )} ${customDatePipe.transform(this.reservationDate.getTime())}`;
  }

  onChangeHourFrom($event) {
    let minute = this.hourFrom.split(':')[1];

    this.hourFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    let hour = this.hourFrom.split(':')[0];

    this.hourFrom = `${hour}:${$event.target.value}`;
  }

  onChangeHourTo($event) {
    let minute = this.hourTo.split(':')[1];

    this.hourTo = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteTo($event) {
    let hour = this.hourTo.split(':')[0];

    this.hourTo = `${hour}:${$event.target.value}`;
  }

  cancelSave() {
    this.cancelEmitter.emit();
  }

  saveReservation() {
    let messagePipe = new MessagesPipe();
    let actualDate = new Date();
    let timePipe = new CustomTimePipe();
    let dateFrom = timePipe.transform(this.reservationDate, this.hourFrom);
    let dateTo = timePipe.transform(this.reservationDate, this.hourTo);

    if (this.utils.compareDates(dateFrom, dateTo) >= 0) {
      this.errorDatesInterval = messagePipe.transform('date_to_greater');
    } else if (this.utils.compareDates(dateFrom, actualDate) < 0) {
      this.errorDatesInterval = messagePipe.transform('date_from_greater');
    } else {
      this.errorDatesInterval = '';
      let reservation = new ReservationWs();
      reservation.room = this.room;
      reservation.dateFrom = dateFrom;
      reservation.dateTo = dateTo;
      reservation.reason = this.reason;

      this.disableButtons = true;
      this.saveEmitter.emit(reservation);
    }
  }
}
