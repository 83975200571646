import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { DalkonValorDevuelto } from '../../../core/models/dalkon.valor.devuelto';
import { UserLdap } from '../../../core/models/user.ldap';
import { Vehicle } from '../../../core/models/vehicle';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { DalkonService } from '../../../shared/services/dalkon.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { UserService } from '../../../shared/services/user.service';
import { VehicleService } from '../../../shared/services/vehicle.service';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { ConfirmedValidator } from './confirmed.validator';
import { ProfileFormService } from './profile-form.service';
import { TypeValidator } from './type.validator';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { BaseService } from '../../../shared/services/base.service';
import {
  LOCAL_CURRENT_USER,
  STATUS_FORBIDDEN,
  STATUS_CONFLICT,
} from '../../../core/constants/const';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  animations: [slideDetailsBooking()],
  providers: [TypeValidator],
})
export class ProfileFormComponent implements OnInit {
  @Output() closeFormEmmitter = new EventEmitter<string>();

  formChangePassword: FormGroup;
  formVehicle: FormGroup;
  formSupportAssistance: FormGroup;
  showFormChangePassword = false;
  showVehicle = false;
  showSupportAssistance = false;
  modalOpen = false;
  activeEdit = false;
  typeForm: string;
  titleForm: string;
  menssageButtonVehicle: string;
  modalConfig: any;
  outside: any;
  userVehicle: Vehicle[];
  typeVehicle: any;
  actionUpdateVehicle = 0;
  user: UserLdap;
  messagesPipe = new MessagesPipe();
  userWs: UserLdapWs;
  showSelectTurn: boolean;
  availableShifts: any;
  userProfile: UserLdapWs;

  constructor(
    private authenticationService: AuthenticationService,
    private profileFormService: ProfileFormService,
    private userService: UserService,
    private fb: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private dalkonService: DalkonService,
    private vehicleService: VehicleService,
    private typeValidator: TypeValidator,
    private baseService: BaseService
  ) {
    this.profileFormService.openModal = this.openModal.bind(this);
    this.profileFormService.closModal = this.closeModal.bind(this);
    this.profileFormService.setUserProfile = this.setUserProfile.bind(this);
  }

  ngOnInit() {
    this.modalConfig = document.getElementById('modalConfig');
    this.outside = document.getElementById('outside');
    this.user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    this.userWs = this.authenticationService.getCurrentUser();
  }

  openModal(type: string) {
    if (!this.modalOpen) {
      this.typeForm = type;
      this.createForm();
      this.getMessage();
      this.modalConfig.style.display = 'block';
      this.outside.style.display = 'block';
      this.getMessage();
      window.setTimeout(() => {
        this.modalConfig.style.opacity = 1;
        this.outside.style.opacity = 1;
        this.modalOpen = true;
      }, 100);
    }
  }

  closeModal(type?: any) {
    if (this.modalOpen) {
      this.showVehicle = false;
      this.showFormChangePassword = false;
      this.showSupportAssistance = false;
      this.modalConfig.style.opacity = 0;
      this.outside.style.opacity = 0;
      window.setTimeout(() => {
        this.modalOpen = false;
        this.modalConfig.style.display = 'none';
        this.outside.style.display = 'none';
        this.removeValue();
        this.closeFormEmmitter.emit(type);
      }, 100);
    }
  }

  setUserProfile(user: UserLdapWs) {
    this.userProfile = user;
    this.showSelectTurn = this.userProfile ? this.userProfile.dinnerShiftRequest : null;
  }

  getAnimate(): string {
    if (this.modalOpen) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  createForm() {
    if (this.typeForm === 'updatePassword') {
      this.showFormChangePassword = true;
      this.formChangePassword = this.fb.group(
        {
          currentPassword: [null, Validators.required],
          newPassword: [null, Validators.required],
          confirmNewPassword: [null, Validators.required],
        },
        {
          validator: ConfirmedValidator('newPassword', 'confirmNewPassword'),
        }
      );
    } else if (this.typeForm === 'modifyVehicle') {
      this.showVehicle = true;
      this.formVehicle = this.fb.group({
        id: [null],
        type: [0, [TypeValidator.typeValidator]],
        license: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{4}[a-zA-Z]{3}$')],
        ],
        model: ['', Validators.required],
        color: [''],
        main: [false],
        userLadp: [null],
      });
      this.getTypesVehicle();
      this.obtainData();
    } else if (this.typeForm === 'supportAssistance') {
      let shiftId: string;
      this.showSupportAssistance = true;
      this.getAvailableShifts();
      if (
        this.userProfile.dinnerShiftFrom != null &&
        this.userProfile.dinnerShiftTo != null
      ) {
        shiftId =
          moment(this.userProfile.dinnerShiftFrom).format('HHmm') +
          '' +
          moment(this.userProfile.dinnerShiftTo).format('HHmm');
      } else if (
        this.userWs.dinnerShiftFrom != null &&
        this.userWs.dinnerShiftTo != null
      ) {
      }
      this.formSupportAssistance = this.fb.group({
        solictParking: '',
        solictDinner: '',
        availableShifts: [shiftId],
      });
      this.obtainData();
    }
  }

  get f() {
    return this.formChangePassword.controls;
  }

  obtainData() {
    this.dalkonService
      .obtainData(this.authenticationService.getToken())
      .subscribe((res: DalkonValorDevuelto) => {
        if (res && res.Vehiculos) {
          this.userVehicle = res.Vehiculos;
          this.userVehicle.forEach((x: Vehicle) => (x.active = false));
        }
      });
  }

  updatePassword() {
    if (this.formChangePassword.valid) {
      this.userService
        .updatePassword(
          this.authenticationService.getToken(),
          this.formChangePassword.get('currentPassword').value,
          this.formChangePassword.get('newPassword').value
        )
        .subscribe(
          (res) => {
            if (res && res.Valor_Devuelto == 'true') {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('update_password_ok'),
                {
                  cssClass: 'alert-success',
                  timeout: 3000,
                }
              );
              this.closeModal();
            } else {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('update_password_fail'),
                {
                  cssClass: 'alert-danger',
                  timeout: 3000,
                }
              );
              this.createForm();
            }
          },
          (error) => {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('update_password_error'),
              {
                cssClass: 'alert-danger',
                timeout: 3000,
              }
            );
            this.closeModal();
          }
        );
    }
  }

  changueMain(vehicle: Vehicle, checked) {
    const newVehicle = new Vehicle(
      vehicle.color,
      vehicle.license,
      checked,
      vehicle.model,
      vehicle.type,
      vehicle.userLadp,
      vehicle.id
    );

    this.vehicleService
      .editVehicle(this.authenticationService.getToken(), newVehicle)
      .subscribe((res) => {
        if (res) {
          this.obtainData();
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_principal_vehicle'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
        }
      });
  }

  updateVehicle() {
    if (this.actionUpdateVehicle === 0) {
      this.addVehicle();
    } else if (this.actionUpdateVehicle === 1) {
      this.editVehicle();
    }
  }

  addVehicle() {
    const type = this.typeVehicle.find(
      (x) => x.type_id == this.formVehicle.value.type
    );
    const newVehicle = new Vehicle(
      this.formVehicle.value.color,
      this.formVehicle.value.license,
      this.formVehicle.value.main,
      this.formVehicle.value.model,
      type,
      this.user
    );

    console.log('newVehicle :>> ', newVehicle);

    this.vehicleService
      .addVehicle(this.authenticationService.getToken(), newVehicle)
      .subscribe((res) => {
        if (res) {
          this.closeEditVehicle();
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(this.messagesPipe.transform('profile_form_vehicle_add'), {
            cssClass: 'alert-success',
            timeout: 3000,
          });
        }
      });
  }

  editVehicle() {
    let type;

    if (this.formVehicle.value.type && this.formVehicle.value.type.type_id) {
      type = this.formVehicle.value.type;
    } else {
      type = this.typeVehicle.find(
        (x) => x.type_id == this.formVehicle.value.type
      );
    }

    const newVehicle = new Vehicle(
      this.formVehicle.value.color,
      this.formVehicle.value.license,
      this.formVehicle.value.main,
      this.formVehicle.value.model,
      type,
      this.user,
      this.formVehicle.value.id
    );

    this.vehicleService
      .editVehicle(this.authenticationService.getToken(), newVehicle)
      .subscribe(
        (res) => {
          if (res) {
            this.closeEditVehicle();
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('profile_edit_vehicle'),
              {
                cssClass: 'alert-success',
                timeout: 3000,
              }
            );
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_edit_vehicle_error'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
        }
      );
  }

  openEditVehicle(vehicle: Vehicle) {
    document.getElementById('formVehicle').style.display = 'flex';
    document.getElementById('listVehicle').style.display = 'none';
    this.actionUpdateVehicle = 1;
    this.menssageButtonVehicle = this.messagesPipe.transform('profile_vehicleEdit_edit');
    this.formVehicle.setValue({
      id: vehicle.id,
      type: vehicle.type,
      license: vehicle.license,
      model: vehicle.model,
      color: vehicle.color,
      main: vehicle.main,
      userLadp: this.user,
    });
  }

  closeEditVehicle() {
    document.getElementById('formVehicle').style.display = 'none';
    document.getElementById('listVehicle').style.display = 'block';
    this.formVehicle.setValue({
      id: null,
      type: 0,
      license: null,
      model: null,
      color: null,
      main: false,
      userLadp: null,
    });
    this.obtainData();
  }

  openNewVehicle() {
    document.getElementById('formVehicle').style.display = 'flex';
    document.getElementById('listVehicle').style.display = 'none';
    this.actionUpdateVehicle = 0;
    this.menssageButtonVehicle = this.messagesPipe.transform('profile_vehicleEdit_add');
    this.formVehicle.setValue({
      id: null,
      type: 0,
      license: null,
      model: null,
      color: null,
      main: false,
      userLadp: this.user,
    });
  }

  removeValue() {
    this.formChangePassword = null;
  }

  getTypesVehicle() {
    this.vehicleService
      .getTypesVehicle(this.authenticationService.getToken())
      .subscribe((res) => {
        if (res) {
          this.typeVehicle = res;
        }
      });
  }

  remoVehicle(id: number) {
    this.vehicleService
      .removeVehicle(this.authenticationService.getToken(), id)
      .subscribe((res) => {
        if (res) {
          this.obtainData();
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(this.messagesPipe.transform('profile_form_vehicle_delete'), {
            cssClass: 'alert-success',
            timeout: 3000,
          });
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(this.messagesPipe.transform('profile_form_vehicle_delete_error'), {
            cssClass: 'alert-success',
            timeout: 3000,
          });
        }
      });
  }

  getMessage() {
    if (this.typeForm === 'updatePassword') {
      this.titleForm = this.messagesPipe.transform('profile_form_pass_mod');
    } else if (this.typeForm === 'modifyVehicle') {
      this.titleForm = this.messagesPipe.transform('profile_form_vehicle');
    } else if (this.typeForm === 'supportAssistance') {
      this.titleForm = this.messagesPipe.transform('profile_form_assistance_config');
    }
  }

  changeCheckDinner(value: any) {
    this.showSelectTurn = value ? true : false;
  }

  getAvailableShifts() {
    const date = moment(new Date()).format('YYYY-MM-DD');
    this.userService
      .getDinnerShifts(this.authenticationService.getToken(), date)
      .subscribe((res) => {
        if (res) {
          this.availableShifts = res;
          let turn: any;
          for (let i = 0; i < this.availableShifts.length; i++) {
            const dateFrom = moment(this.availableShifts[i].shiftFrom).format(
              'HH:mm'
            );
            const dateTo = moment(this.availableShifts[i].shiftTo).format(
              'HH:mm'
            );
            turn = {
              id:
                moment(this.availableShifts[i].shiftFrom).format('HHmm') +
                '' +
                moment(this.availableShifts[i].shiftTo).format('HHmm'),
              shiftFrom: this.availableShifts[i].shiftFrom,
              shiftTo: this.availableShifts[i].shiftTo,
              shiftFromAux: dateFrom,
              shiftToAux: dateTo,
            };
            this.availableShifts[i] = turn;
          }
        }
      });
  }

  saveAssistante() {
    let building;
    let department;
    const user = new UserLdapWs();

    user.name = this.userProfile.name;
    user.email = this.userProfile.email;
    user.image = this.userProfile.image;
    user.dinnerShiftRequest =  $('#solictDinner').is(':checked');
    user.parkingRequest = $('#solictParking').is(':checked');
    const shiftId = this.formSupportAssistance.controls['availableShifts'].value;
    const turnError = !user.dinnerShiftRequest || user.dinnerShiftRequest && shiftId > 0 ? false : true;
    let shiftFrom = this.userProfile.dinnerShiftFrom;
    let shiftTo = this.userProfile.dinnerShiftTo;
    this.availableShifts.forEach(element => {
      if (element.id === shiftId) {
        shiftFrom = element.shiftFrom;
        shiftTo = element.shiftTo;
      }
    });
    user.dinnerShiftFrom = shiftFrom;
    user.dinnerShiftTo = shiftTo;
    user.showHotdeskReservation = this.userProfile.showHotdeskReservation;
    building = this.userProfile.building;
    department = this.userProfile.department;
    user.headquarters = this.userProfile.headquarters;

    if (!turnError) {
      this.userService
        .updateProfile(this.authenticationService.getToken(), user)
        .subscribe(
          (response) => {
            if (response) {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('updateProfile_success'),
                { cssClass: 'alert-success', timeout: 3000 }
              );
              this.closeModal('supportAssitance');
            }
          },
          (error) => {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(this.messagesPipe.transform('profile_form_update_error'), {
              cssClass: 'alert-success',
              timeout: 3000,
            });
          }
        );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform('assistance_text_turn_selection_error'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }

  checkShiftPriority() {
    const turn = this.formSupportAssistance.controls['availableShifts'].value;
    let res = true;
    this.availableShifts.forEach((element) => {
      if (element.id === turn) {
        res = false;
      }
    });

    return res;
  }
}
