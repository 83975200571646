import { Component, OnInit } from "@angular/core";
import { MessagesPipe } from "src/app/shared/pipes/messages.pipe";
import { DalkonValorDevuelto } from "src/app/core/models/dalkon.valor.devuelto";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { DalkonService } from "src/app/shared/services/dalkon.service";
import { BaseService } from "src/app/shared/services/base.service";
import { FlashMessagesService } from "src/app/shared/services/flash-messages.service";
import { STATUS_FORBIDDEN } from "src/app/core/constants/const";
import { LOGOUT_TIMEOUT } from "src/app/core/constants/consts";
import { MenuPermissions } from "src/app/core/models/menu.permissions";

@Component({
  selector: "app-home-parking",
  templateUrl: "./home-parking.component.html",
  styleUrls: ["./home-parking.component.scss"]
})
export class HomeParkingComponent implements OnInit {
  messagesPipe: MessagesPipe;
  parking: DalkonValorDevuelto;
  permissions: MenuPermissions;

  constructor(
    private authenticationService: AuthenticationService,
    private dalkonService: DalkonService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService
  ) {
    this.messagesPipe = new MessagesPipe();
  }

  ngOnInit() {
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
    this.loadParking();
  }

  loadParking(): void {
    this.dalkonService
      .obtainData(this.authenticationService.getToken())
      .subscribe(
        response => {
          this.parking = response;
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.loadParking();
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.loadParking();
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform("error_dalkonParking")
            );
          }
        }
      );
  }
}
