import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  messagePipe = new MessagesPipe();
  resetPasswordForm: FormGroup;
  resetError: string;
  emailActive: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private spinnerService: SpinnerService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    const emailRegex =
      '^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$';

    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }

  setEmailActive() {
    this.emailActive = true;
  }

  removeEmailActive() {
    if (this.resetPasswordForm.controls['email'].value.length === 0) {
      this.emailActive = false;
    }
  }

  requestNewPassword() {
    const email = this.resetPasswordForm.controls['email'].value;

    this.spinnerService.show();

    this.loginService
      .resetPassword(email)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response) => {
          if (response.Valor_Devuelto === 'true') {
            this.router.navigateByUrl('/login');

            this.flashMessagesService.show(
              this.messagePipe.transform('resetPassword_message_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          } else {
            this.resetError = response.Errores[0];
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagePipe.transform('resetPassword_message_error'),
            { cssClass: 'alert-error', timeout: 3000 }
          );
        }
      );
  }
}
