import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  AVAILABLE_DURATION,
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES,
  AVAILABLE_MINUTES_SPEED,
  RESERVATION_TYPE_NONE
} from '../../../core/constants/consts';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { ReservationTypesWS } from '../../../core/models/reservation.types.ws';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { RoomListWs } from '../../../core/models/room.list.ws';
import { Router } from '@angular/router';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationSpeedNewDetail',
  templateUrl: 'reservation.speed.new.detail.html',
  styleUrls: ['new-reservation.scss'],
  animations: [slideDetailsBooking()]
})
export class ReservationSpeedNewDetail implements OnInit, OnChanges {
  @Input() showForm: boolean;
  @Input() reservationTypes: ReservationTypesWS[];
  @Output() closeFormEmmitter = new EventEmitter<any>();
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('datePickerMaxDate', null) datePickerMaxDate: MyDatePicker;
  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectDuration', null) selectDuration: any;
  @ViewChild('selectReservationTypes', null) selectReservationTypes: any;
  reservationTypeSelected: ReservationTypesWS;
  defaultReservationTypeSelected = RESERVATION_TYPE_NONE;
  reservationForm: FormGroup;
  datepickerOptions: any;
  reservationDate: Date;
  reservationMaxDate: Date;
  hoursFrom: string[];
  reservationDateFrom: string;
  reservationDuration: number;
  minutesFrom = AVAILABLE_MINUTES_SPEED;
  duration = AVAILABLE_DURATION;

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {
    let minDate;
    let minHourIndex;
    this.datepickerOptions = {};
    this.reservationTypeSelected = new ReservationTypesWS();
    let dateStringPipe = new DateStringPipe();

    //Remove the hours from selects
    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        dateStringPipe.transform(minDate, 'HH')
      );
      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    this.reservationForm = this.formBuilder.group({
      reason: ['', Validators.required]
    });

    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );
  }

  ngOnChanges() {
    if (this.showForm) {
      let actualDate = new Date();
      let maxPeriodicityDate = this.authenticationService.getMaxReservationDate();
      let monthMaxPeriodicity = maxPeriodicityDate.getMonth() + 1;
      let month = actualDate.getMonth() + 1;

      this.reservationDate = actualDate;
      this.reservationDuration = parseInt(this.duration[0]);
      this.reservationMaxDate = maxPeriodicityDate;

      this.datePickerElement.clearDate();
      this.datePickerMaxDate.clearDate();
      this.datePickerElement.selectDate({
        day: actualDate.getDate(),
        month: month,
        year: actualDate.getFullYear()
      });
      this.datePickerMaxDate.selectDate({
        day: maxPeriodicityDate.getDate(),
        month: monthMaxPeriodicity,
        year: maxPeriodicityDate.getFullYear()
      });

      this.selectDuration.nativeElement.selectedIndex = 0;

      this.setPositionFrom();

      if (this.reservationTypes && this.reservationTypes.length > 0) {
        this.reservationTypeSelected = this.reservationTypes.filter(type => {
          return this.defaultReservationTypeSelected == type.id;
        })[0];
        this.selectReservationTypes.nativeElement.selectedIndex = this.reservationTypes.indexOf(
          this.reservationTypeSelected
        );
      }
    }
  }

  checkReservations() {
    let periodicityCorrect;
    let comparePeriodicityDates;
    let dateTo;
    let roomList;
    let timePipe = new CustomTimePipe();
    let messagesPipe = new MessagesPipe();
    let dateFrom = timePipe.transform(
      this.reservationDate,
      this.reservationDateFrom
    );
    let actualDate = new Date();

    if (this.utils.compareDates(dateFrom, actualDate) < 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_from_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else {
      dateTo = new Date(dateFrom.getTime());
      dateTo.setMinutes(dateTo.getMinutes() + this.reservationDuration);

      if (dateTo.getDay() > dateFrom.getDay()) {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          messagesPipe.transform('duraton_greater'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
      } else {
        if (
          this.reservationTypeSelected.id != this.defaultReservationTypeSelected
        ) {
          comparePeriodicityDates = this.utils.compareDatesWithoutTime(
            this.reservationDate,
            this.reservationMaxDate
          );

          if (comparePeriodicityDates >= 0) {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              messagesPipe.transform('date_max_periodicity_greater'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            periodicityCorrect = false;
          } else {
            periodicityCorrect = true;
          }
        } else {
          periodicityCorrect = true;
          this.reservationTypeSelected = null;
          this.reservationMaxDate = null;
        }

        if (periodicityCorrect) {
          roomList = new RoomListWs(
            dateFrom,
            new Date(),
            this.reservationForm.controls['reason'].value,
            null,
            null,
            this.reservationTypeSelected,
            this.reservationMaxDate
          );
          roomList.dateTo = null;
          roomList.dateToInMillis = null;
          roomList.duration = this.reservationDuration;

          localStorage.setItem('roomSpeedFilter', JSON.stringify(roomList));

          this.router.navigate(['/menu/availableSpeedRooms']);
        }
      }
    }
  }

  setPositionFrom(): void {
    let hourS;
    let minuteS;
    let positionHour;
    let positionMinute;
    let actualDate = new Date();
    let minSelectHour = this.authenticationService.getMinReservationTime();
    let dateStringPipe = new DateStringPipe();
    let minuteNum = parseInt(
      dateStringPipe.transform(actualDate.toString(), 'mm')
    );

    if (minuteNum > 0 && minuteNum < 10) {
      actualDate.setMinutes(10);
    } else if (minuteNum > 10 && minuteNum < 20) {
      actualDate.setMinutes(20);
    } else if (minuteNum > 20 && minuteNum < 30) {
      actualDate.setMinutes(30);
    } else if (minuteNum > 30 && minuteNum < 40) {
      actualDate.setMinutes(40);
    } else if (minuteNum > 40 && minuteNum < 50) {
      actualDate.setMinutes(50);
    } else if (minuteNum > 50) {
      actualDate.setHours(actualDate.getHours() + 1);
      actualDate.setMinutes(0);
    }

    hourS = dateStringPipe.transform(actualDate.toString(), 'HH');
    minuteS = dateStringPipe.transform(actualDate.toString(), 'mm');

    if (minSelectHour != null) {
      if (actualDate >= minSelectHour) {
        positionHour = this.hoursFrom.indexOf(hourS);
        positionMinute = this.minutesFrom.indexOf(minuteS);
      } else {
        positionHour = this.hoursFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'HH')
        );
        positionMinute = this.minutesFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'mm')
        );
      }
    } else {
      positionHour = this.hoursFrom.indexOf(hourS);
      positionMinute = this.minutesFrom.indexOf(minuteS);
    }

    this.selectHourFrom.nativeElement.selectedIndex = positionHour;
    this.selectMinuteFrom.nativeElement.selectedIndex = positionMinute;

    this.reservationDateFrom = `${
      this.selectHourFrom.nativeElement.options[
        this.selectHourFrom.nativeElement.selectedIndex
      ].value
    }:${
      this.selectMinuteFrom.nativeElement.options[
        this.selectMinuteFrom.nativeElement.selectedIndex
      ].value
    }`;
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  onDateMaxPeriodicity($event) {
    if ($event.formatted.length > 0) {
      this.reservationMaxDate = new Date();
      this.reservationMaxDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationMaxDate = null;
    }
  }

  onChangeHourFrom($event) {
    let minute = this.reservationDateFrom.split(':')[1];

    this.reservationDateFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    let hour = this.reservationDateFrom.split(':')[0];

    this.reservationDateFrom = `${hour}:${$event.target.value}`;
  }

  onChangeDuration($event) {
    let duration = $event.target.value;
    this.reservationDuration = parseInt(duration);
  }

  onChangeReservationType($event) {
    let reservationId = parseInt($event.target.value);
    this.reservationTypeSelected = this.reservationTypes.filter(type => {
      return type.id == reservationId;
    })[0];
  }

  closeFormNewReserve() {
    this.showForm = false;
    this.closeFormEmmitter.emit();
  }

  getNewReservationAnimate(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }
}
