import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { SensorService } from '../../shared/services/sensor.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import moment = require('moment');

@Component({
  selector: 'app-dinning-occupation',
  templateUrl: './dinning-occupation.component.html',
  styleUrls: ['./dinning-occupation.component.scss'],
})
export class DinningOccupationComponent implements OnInit {
  // Percentage
  data: number[] = [0, 0];

  // Labels
  labels: Label[] = ['', ''];

  // Colors
  colors = [
    {
      backgroundColor: ['rgb(192 60 89)', 'rgb(142 186 229)'],
    },
  ];

  // Chart options
  chartOptions: ChartOptions = {};

  chartType: ChartType = 'doughnut';
  chartLegend = true;

  urlMenu: any;
  language: string;
  url: String;

  message: MessagesPipe;

  updatedTime: any;

  constructor(
    private sensorService: SensorService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    setInterval(() => this.reloadPage(), 30000);
  }

  reloadPage() {
    this.ngOnInit();
  }

  ngOnInit() {
    const date = new Date();
    this.updatedTime = moment(date).format('HH:mm:ss');
    this.message = new MessagesPipe();
    this.language = this.languageEnglish()
      ? 'en'
      : navigator.language.substr(0, 2);
    this.message.setLanguage(this.language);
    const occupancyLevel = this.message.transform(
      'occupation_text_occupancy_level'
    );

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: occupancyLevel,
        fontSize: 20,
        fontColor: 'rgba(0,0,0,1)',
        display: true,
      },
      legend: {
        position: 'bottom',
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
          color: '#fff',
        },
      },
    };
    this.url = this.route.snapshot['_routerState'].url as String;
    this.route.params.forEach((params: Params) => {
      if (this.url.includes('/dinning-occupation-headquarter/')) {
        this.getSensorDataByHeadquarterId(params['headquarterId']);
      } else {
        this.getSensorData(params['token']);
      }
    });
  }

  getSensorData(token: string) {
    this.sensorService.getSensorData(token).subscribe((res) => {
      const free = this.message.transform('occupation_text_free');
      const occupied = this.message.transform('occupation_text_occupied');

      if (res) {
        this.urlMenu = res.urlMenu
          ? this.sanitizer.bypassSecurityTrustResourceUrl(res.urlMenu)
          : '';
        const freePercentage =
          100 - (res.occupationPercentage ? res.occupationPercentage : 0);
        this.data = [
          res.occupationPercentage <= 100 ? res.occupationPercentage : 100,
          freePercentage < 0 ? 0 : freePercentage,
        ];

        this.labels = [
          `${occupied} ${res.occupationPercentage}%`,
          `${free} ${this.data[1]}%`,
        ];
      }
    });
  }

  getSensorDataByHeadquarterId(headquarterId: number) {
    this.sensorService
      .getSensorDataByHeadquarterId(headquarterId)
      .subscribe((res) => {
        this.message.setLanguage(this.language);
        const free = this.message.transform('occupation_text_free');
        const occupied = this.message.transform('occupation_text_occupied');

        if (res) {
          this.urlMenu = res.urlMenu
            ? this.sanitizer.bypassSecurityTrustResourceUrl(res.urlMenu)
            : '';
          const freePercentage =
            100 - (res.occupationPercentage ? res.occupationPercentage : 0);
          this.data = [
            res.occupationPercentage <= 100 ? res.occupationPercentage : 100,
            freePercentage < 0 ? 0 : freePercentage,
          ];

          this.labels = [
            `${occupied} ${res.occupationPercentage}%`,
            `${free} ${this.data[1]}%`,
          ];
        }
      });
  }

  languageEnglish(): boolean {
    return environment['languageEnglish'];
  }
}
