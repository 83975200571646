import {
  DEFAULT_AREA_DATA_API_PATH,
  DEFAULT_AREA_DINNER_DATA_API_PATH,
  DEFAULT_AREA_HOTDESK_DATA_API_PATH,
  DEFAULT_FLOOR_DATA_API_PATH,
  DEFAULT_PARKING_DATA_API_PATH,
  DEFAULT_HOLIDAY_DATA_API_PATH,
} from 'src/app/core/constants/const';
import {OptionsEnv} from 'src/app/core/models/options.env';

export const environment = {production: false};
export let NAME = 'santa-lucia';
export const SERVICE_BASE_URL = 'https://rosa.santalucia.es/ws/';
export const COMPONENT_OAUTH_PATH = 'registerToken';
export const LOGIN_RETURN_URL = `${SERVICE_BASE_URL}/#/${COMPONENT_OAUTH_PATH}`;
export const CLIENT_ID = '6b6e84630bdf431d6bff613e3a5c48a9';
export const SERVICE_LOGIN_URL = `${SERVICE_BASE_URL}/web/access?return_url=${encodeURIComponent(LOGIN_RETURN_URL)}&client_id=${CLIENT_ID}`;
export const PARKING_DATA_API_PATH = DEFAULT_PARKING_DATA_API_PATH;

export const AREA_DATA_API_PATH = DEFAULT_AREA_DATA_API_PATH;

export const FLOOR_DATA_API_PATH = DEFAULT_FLOOR_DATA_API_PATH;
export const AREA_DINNER_DATA_API_PATH = DEFAULT_AREA_DINNER_DATA_API_PATH;
export const AREA_HOTDESK_DATA_API_PATH = DEFAULT_AREA_HOTDESK_DATA_API_PATH;

export const HOLIDAY_DATA_API_PATH  = DEFAULT_HOLIDAY_DATA_API_PATH;

export let OPTIONS_RESTRICTION: OptionsEnv = {
    dalkon_token: false
};