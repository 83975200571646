import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { HeadquartersWS } from '../../core/models/headquarters.ws';

@Injectable()
export class HeadquartersService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getHeadquarterList(token: string): Observable<HeadquartersWS[]> {
    let bodyString = JSON.stringify({});
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/headquarters/list?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersWS[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getFinalHeadquarterListAndNotUserHeadquarters(
    token: string
  ): Observable<HeadquartersWS[]> {
    let bodyString = JSON.stringify({});
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/headquarters/otherHeadquartersList?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersWS[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getFinalHeadquarterList(token: string): Observable<HeadquartersWS[]> {
    let bodyString = JSON.stringify({});
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/headquarters/finalHeadquartersList?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersWS[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
