import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES,
  RESERVATION_TYPE_NONE,
} from '../../../core/constants/consts';
import { EquipementWs } from '../../../core/models/equipement.ws';
import { ReservationTypesWS } from '../../../core/models/reservation.types.ws';
import { RoomListWs } from '../../../core/models/room.list.ws';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'app-reservation-new-form',
  templateUrl: 'reservation.new.detail.html',
  styleUrls: ['new-reservation.scss'],
  animations: [slideDetailsBooking()],
})
export class ReservationNewDetailComponent implements OnInit, OnChanges {
  @Input() equimenent: EquipementWs[];
  @Input() reservationTypes: ReservationTypesWS[];
  @Input() showForm: boolean;

  @Output() closeFormEmmitter = new EventEmitter<any>();

  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectHourTo', null) selectHourTo: any;
  @ViewChild('selectMinuteTo', null) selectMinuteTo: any;
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('datePickerMaxDate', null) datePickerMaxDate: MyDatePicker;
  @ViewChild('selectReservationTypes', null) selectReservationTypes: any;

  reservationTypeSelected: ReservationTypesWS;
  defaultReservationTypeSelected = RESERVATION_TYPE_NONE;
  reservationForm: FormGroup;
  reservationDate: Date;
  reservationMaxDate: Date;
  reservationDateFrom: string;
  reservationDateTo: string;
  equipementSelected: EquipementWs[];
  numberPattern: string;
  datepickerOptions: any;
  hoursFrom: string[];
  hoursTo: string[];
  minutesFrom = AVAILABLE_MINUTES;
  minutesTo = AVAILABLE_MINUTES;
  currentUser: UserLdapWs;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private utils: Utils,
    private authenticationService: AuthenticationService
  ) {
    const dateStringPipe = new DateStringPipe();
    let minDate;
    let minHourIndex;

    this.equipementSelected = [];
    this.numberPattern = '^[0-9]*$';
    this.datepickerOptions = {};
    this.reservationTypeSelected = new ReservationTypesWS();

    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        dateStringPipe.transform(minDate, 'HH')
      );

      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
      this.hoursTo = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
      this.hoursTo = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    this.reservationForm = this.formBuilder.group({
      assistants: [
        '2',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      reason: ['', Validators.required],
    });

    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  ngOnChanges() {
    if (this.showForm) {
      const actualDate = new Date();
      const maxPeriodicityDate = this.authenticationService.getMaxReservationDate();
      const month = actualDate.getMonth() + 1;
      const monthMaxPeriodicity = maxPeriodicityDate.getMonth() + 1;

      // Select current date
      this.datePickerElement.clearDate();
      this.datePickerMaxDate.clearDate();
      this.datepickerOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxReservationDate()
      );

      this.reservationDate = actualDate;
      this.reservationMaxDate = maxPeriodicityDate;

      this.datePickerElement.selectDate({
        day: actualDate.getDate(),
        month: month,
        year: actualDate.getFullYear(),
      });

      this.datePickerMaxDate.selectDate({
        day: maxPeriodicityDate.getDate(),
        month: monthMaxPeriodicity,
        year: maxPeriodicityDate.getFullYear(),
      });

      // Select current hours interval
      this.setPositionFrom();
      this.setPositionTo();

      this.reservationDateFrom = `${
        this.selectHourFrom.nativeElement.options[
          this.selectHourFrom.nativeElement.selectedIndex
        ].value
      }:${
        this.selectMinuteFrom.nativeElement.options[
          this.selectMinuteFrom.nativeElement.selectedIndex
        ].value
      }`;

      this.equipementSelected = [];

      if (this.reservationTypes && this.reservationTypes.length > 0) {
        this.reservationTypeSelected = this.reservationTypes.filter((type) => {
          return this.defaultReservationTypeSelected === type.id;
        })[0];

        this.selectReservationTypes.nativeElement.selectedIndex = this.reservationTypes.indexOf(
          this.reservationTypeSelected
        );
      }
    }
  }

  setPositionFrom(): void {
    let hourS;
    let minuteS;
    let positionHour;
    let positionMinute;
    const actualDate = new Date();
    const minSelectHour = this.authenticationService.getMinReservationTime();
    const dateStringPipe = new DateStringPipe();
    const minuteNum = parseInt(
      dateStringPipe.transform(actualDate.toString(), 'mm'),
      0
    );

    if (minuteNum > 0 && minuteNum < 15) {
      actualDate.setMinutes(15);
    } else if (minuteNum > 15 && minuteNum < 30) {
      actualDate.setMinutes(30);
    } else if (minuteNum > 30 && minuteNum < 45) {
      actualDate.setMinutes(45);
    } else if (minuteNum > 45) {
      actualDate.setHours(actualDate.getHours() + 1);
      actualDate.setMinutes(0);
    }

    hourS = dateStringPipe.transform(actualDate.toString(), 'HH');
    minuteS = dateStringPipe.transform(actualDate.toString(), 'mm');

    if (minSelectHour != null) {
      if (actualDate >= minSelectHour) {
        positionHour = this.hoursFrom.indexOf(hourS);
        positionMinute = this.minutesFrom.indexOf(minuteS);
      } else {
        positionHour = this.hoursFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'HH')
        );
        positionMinute = this.minutesFrom.indexOf(
          dateStringPipe.transform(minSelectHour.toString(), 'mm')
        );
      }
    } else {
      positionHour = this.hoursFrom.indexOf(hourS);
      positionMinute = this.minutesFrom.indexOf(minuteS);
    }

    this.selectHourFrom.nativeElement.selectedIndex = positionHour;
    this.selectMinuteFrom.nativeElement.selectedIndex = positionMinute;

    this.reservationDateFrom = `${
      this.selectHourFrom.nativeElement.options[
        this.selectHourFrom.nativeElement.selectedIndex
      ].value
    }:${
      this.selectMinuteFrom.nativeElement.options[
        this.selectMinuteFrom.nativeElement.selectedIndex
      ].value
    }`;
  }

  setPositionTo(): void {
    let minutesFrom;

    if (
      this.reservationDateFrom != null &&
      this.reservationDateFrom.length > 0
    ) {
      minutesFrom = parseInt(this.reservationDateFrom.split(':')[1], 0);

      if (minutesFrom === 0) {
        this.selectHourTo.nativeElement.selectedIndex = this.selectHourFrom.nativeElement.selectedIndex;
        this.selectMinuteTo.nativeElement.selectedIndex = 2;
      } else if (minutesFrom === 15) {
        this.selectHourTo.nativeElement.selectedIndex = this.selectHourFrom.nativeElement.selectedIndex;
        this.selectMinuteTo.nativeElement.selectedIndex = 3;
      } else if (minutesFrom === 30) {
        if (
          this.selectHourTo.nativeElement.options.length >
          this.selectHourFrom.nativeElement.selectedIndex + 1
        ) {
          this.selectHourTo.nativeElement.selectedIndex =
            this.selectHourFrom.nativeElement.selectedIndex + 1;
          this.selectMinuteTo.nativeElement.selectedIndex = 0;
        } else {
          this.selectHourTo.nativeElement.selectedIndex = this.selectHourFrom.nativeElement.selectedIndex;
          this.selectMinuteTo.nativeElement.selectedIndex = 3;
        }
      } else {
        if (
          this.selectHourTo.nativeElement.options.length >
          this.selectHourFrom.nativeElement.selectedIndex + 1
        ) {
          this.selectHourTo.nativeElement.selectedIndex =
            this.selectHourFrom.nativeElement.selectedIndex + 1;
          this.selectMinuteTo.nativeElement.selectedIndex = 1;
        } else {
          this.selectHourTo.nativeElement.selectedIndex = this.selectHourFrom.nativeElement.selectedIndex;
          this.selectMinuteTo.nativeElement.selectedIndex = 3;
        }
      }

      this.reservationDateTo = `${
        this.selectHourTo.nativeElement.options[
          this.selectHourTo.nativeElement.selectedIndex
        ].value
      }:${
        this.selectMinuteTo.nativeElement.options[
          this.selectMinuteTo.nativeElement.selectedIndex
        ].value
      }`;
    }
  }

  checkReservations() {
    let periodicityCorrect;
    let roomList;
    let comparePeriodicityDates;
    const timePipe = new CustomTimePipe();
    const messagesPipe = new MessagesPipe();
    const dateFrom = timePipe.transform(
      this.reservationDate,
      this.reservationDateFrom
    );
    const dateTo = timePipe.transform(
      this.reservationDate,
      this.reservationDateTo
    );
    const actualDate = new Date();

    if (this.utils.compareDates(dateFrom, actualDate) < 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_from_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else if (this.utils.compareDates(dateFrom, dateTo) >= 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_to_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else {
      if (
        this.reservationTypeSelected.id !== this.defaultReservationTypeSelected
      ) {
        comparePeriodicityDates = this.utils.compareDatesWithoutTime(
          this.reservationDate,
          this.reservationMaxDate
        );

        if (comparePeriodicityDates >= 0) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            messagesPipe.transform('date_max_periodicity_greater'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
          periodicityCorrect = false;
        } else {
          periodicityCorrect = true;
        }
      } else {
        periodicityCorrect = true;
        this.reservationTypeSelected = null;
        this.reservationMaxDate = null;
      }

      if (periodicityCorrect) {
        roomList = new RoomListWs(
          dateFrom,
          dateTo,
          this.reservationForm.controls['reason'].value,
          this.reservationForm.controls['assistants'].value,
          this.equipementSelected,
          this.reservationTypeSelected,
          this.reservationMaxDate,
          null,
          this.currentUser.headquarters.id
        );

        localStorage.setItem('roomFilter', JSON.stringify(roomList));

        this.router.navigate(['/menu/availableRooms']);
      }
    }
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  onDateMaxPeriodicity($event) {
    if ($event.formatted.length > 0) {
      this.reservationMaxDate = new Date();
      this.reservationMaxDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationMaxDate = null;
    }
  }

  onChangeHourFrom($event) {
    const minute = this.reservationDateFrom.split(':')[1];

    this.reservationDateFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    const hour = this.reservationDateFrom.split(':')[0];

    this.reservationDateFrom = `${hour}:${$event.target.value}`;
  }

  onChangeHourTo($event) {
    const minute = this.reservationDateTo.split(':')[1];

    this.reservationDateTo = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteTo($event) {
    const hour = this.reservationDateTo.split(':')[0];

    this.reservationDateTo = `${hour}:${$event.target.value}`;
  }

  onChangeReservationType($event) {
    const reservationId = parseInt($event.target.value, 0);
    this.reservationTypeSelected = this.reservationTypes.filter((type) => {
      return type.id === reservationId;
    })[0];
  }

  addEquipement(equipement: EquipementWs) {
    if (this.equipementSelected.indexOf(equipement) > -1) {
      this.equipementSelected.splice(
        this.equipementSelected.indexOf(equipement),
        1
      );
    } else {
      this.equipementSelected.push(equipement);
    }
  }

  closeFormNewReserve() {
    this.showForm = false;

    this.reservationForm.reset();
    this.closeFormEmmitter.emit();
  }

  getNewReservationAnimate(): string {
    return this.showForm ? ANIMATE_CLASS_ACTIVE : ANIMATE_CLASS_INACTIVE;
  }
}
