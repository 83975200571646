import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { AuthResponse, ServerHashParamKeys } from 'msal';
import { Subscription } from 'rxjs';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NAME } from 'src/environments/environment';
import globals = require('../../globals');

@Component({
  selector: 'app-login-azure',
  templateUrl: './login-azure.component.html',
  styleUrls: ['./login-azure.component.scss'],
})
export class LoginAzureComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  messagesPipe = new MessagesPipe();
  title = NAME;
  isIframe = false;
  loggedIn = false;
  accessToken: string;
  userEmail: string;
  clientType = 'angular';

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private loginService: LoginService,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkAccount();
    this.subscriptions.push(
      this.broadcastService.subscribe('msal:loginSuccess', () => {
        this.checkAccount();
        this.loginService
          .validateTokenAzure(this.accessToken, this.userEmail, this.clientType)
          .subscribe(
            (userData: any) => {
              if (userData) {
                this.authenticationService.saveToken(userData);
                this.checkRedirection(userData);
              }
            },
            (error) => {
              this.flashMessagesService.show(
                this.messagesPipe.transform('incorrect_login'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );
            }
          );
      })
    );

    this.subscriptions.push(
      this.broadcastService.subscribe('msal:acquireTokenFailure', () => {
        this.authService.loginRedirect();
      })
    );

    this.subscriptions.push(
      this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {
        console.log('acquireTokenSuccess');
        console.log(payload);
      })
    );

    this.subscriptions.push(
      this.broadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
        console.log('acquireTokenFailure');
        console.log(payload);
      })
    );

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        this.authService.loginRedirect();
        return;
      }
    });

    if (!this.loggedIn) {
      if (!this.authService.getLoginInProgress()) {
        this.authService.loginRedirect();
      }
    } else {
      const data = this.authService.getAccount();
      const token = localStorage.getItem('msal.idtoken');
      this.loginService
        .validateTokenAzure(
          token,
          data.idToken.preferred_username,
          this.clientType
        )
        .subscribe(
          (userData: any) => {
            if (userData) {
              this.authenticationService.saveToken(userData);
              this.checkRedirection(userData);
            }
          },
          (error) => {
            this.flashMessagesService.show(
              this.messagesPipe.transform('incorrect_login'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  logout() {
    this.authService.logout();
  }

  checkRedirection(userData: any): void {
    if (globals.redirectPage.length > 0) {
      this.router.navigate([globals.redirectPage]);
      globals.redirectPage = '';
    } else {
      if (userData.headquarters && userData.headquarters.id) {
        this.router.navigate(['/menu/home']);
      } else {
        this.router.navigate(['/menu/profile']);
      }
    }
  }
}
